<template>
  <v-dialog v-model="sign_process" class="sign-modal" persistent fullscreen >
    <div class="sign-container">      
        <div class="sign-wrapper-header" style="font-size: 14px; flex: 0 1 66px; padding: 0 25px 0 75px;  display: flex; align-items: stretch; " :style="{backgroundColor: company.branding.header }">
          <div style="flex: 1 0; line-height: 66px;"><strong>{{company.name}}</strong></div>
          <div style="flex: 1 0; text-align: right; line-height: 66px;">{{active + 1}} of {{uploads.length}} Documents</div>
          <div style="flex: 0 1 66px; text-align: right; line-height: 66px;">
            <hb-link
              color="primary"
              text
              dark
              :disabled="isLoading($options.name)"
              :loading="isLoading($options.name)"
              @click="$emit('close')"
          ><hb-icon>mdi-close</hb-icon>

          </hb-link>
        </div>
      </div>
  
      <div class="sign-flow">
        <iframe v-if="active_document.foreign_id" width="100%" height="100%;" style="border: 0"  :src="`${signing_endpoint}/s/${active_document.foreign_id}?company_id=${company.id}`"></iframe>
        <div v-if="!is_loaded" class="document-loader">
          <v-progress-circular
            indeterminate
            size="48" 
            width="2"
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script type="text/babel">
    import api from '../../assets/api.js';
    import Loader from '../assets/CircleSpinner.vue';
    import Status from '../includes/Messages.vue';
    import moment from 'moment';
    import { EventBus } from '../../EventBus.js';
    
    export default {
        name: "SignDocumentWrapper",
        data() {
            return {
                company: {
                  id: "owncb9a086e25a74bc584a6a7a349e59574",
                  name: "Storelocal Storage",
                  branding: {
                    header: '#F1F7EE',
                    primary_action: '#F45F30',
                  }
                }, 
                is_loaded: false, 
                subdomain: null, 
                sign_process: true,
                document_ready: false,
                session: null,
                upload_id: null,
                saving: false,
                signing_complete: false,
                active: 0,
                bladidoActionIcons: [ 'mdi-forum', 'mdi-currency-usd', 'mdi-information-outline', 'mdi-account-tie-voice', 'mdi-close' ],
                defualtStyleList: []
            }
        },
        props: {
            uploads: {
                type: Array,
                required: true
            },
            signer_id: {
                required: true
            },
            property_id: {
                required: true
            },
            close: {
                type: Boolean,
                default: true
            },
            remote: {
              type: Boolean,
              required: false
            },
            unit_id: {
              required: false
            },
            appId: {
              type: String,
              default: ""
            }
        },
        components:{
            Loader,
            Status
        },
        async created (){
            window.addEventListener('message',  this.eventCallback);

            var paths = location.hostname.split('.');
            this.subdomain = paths[0];

        },
        destroyed(){
            window.removeEventListener('message' ,  this.eventCallback);

        },
        filters:{

        },
        computed:{
          active_document(){
              return this.uploads[this.active] || {}
          },
          signing_endpoint(){
            return process.env.VUE_APP_SIGNING_ENDPOINT
          }
        },
   
        methods: {
          moveNext(){

          }, 
       

            async eventCallback(e){
                if (e && e.data) {
                    try {
                        var message = {}
                        try{
                          message = JSON.parse(e.data); 
                        } catch(err){
                          message = e.data;
                        }
                        switch( message.event) {
                            case 'session_view.document.loaded':
                                this.is_loaded = true;
                                this.$emit('loaded');
                                break;
                            case 'session_view.document.completed':
                                
                                await this.updateUpload(message.data);

                                this.active++;

                                if(!this.active_document.foreign_id) {
                                  this.signing_complete = true;
                                  this.$emit('completed', this.active_document);
                                  return;
                                };

                                if(!this.active_document) alert("An error occurred during signing, please close this window and try again.");

                                
                                break;
                            case 'session_view.document.exception':
                                this.$emit('exception');
                                break;
                        }

                        if (message && message.event) {
                            console.log(message.event, message.data);
                        }
                    } catch(e) {
                        console.log(e);
                    }
                }
            },
            async updateUpload(data = {}){
              
              let body = {
                  property_id: this.property_id,
                  signer_id: this.active_document.signers[0].id,
                  appId: this.appId || "",
                  key: data.key,
                  // template_id: data.template_id
                }

                if (this.remote) {
                  body['remote'] = this.remote
                  body["unit_id"] = this.unit_id
                }

                let response = await api.put(this, api.UPLOADS + 'panda-docs/' + data.file_id + '/signed', body);

                // update status to complete
                this.active_document.complete = 1;
                this.$emit('updateChecklistItem', response);

            },
        }
    }
</script>

<style scoped>

  .walkthrough-header{
    flex-shrink: 1;
    z-index: 1000;
  }

  .sign-container{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content:stretch;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: white;
    z-index: 100;
  }

  .img-view{
    position: relative;
    flex: 0 0 150px;

  }
  .img-view img{
    max-width: 40px;
    width: 100%;
  }
  .pdf-holder{
    padding: 0px;

  }

  .sign-flow{
    background: #F9FAFB;
    border: none;
    width: 100%;
    flex: 1 0;
    z-index: 0;
    height: 100%;

  }
  .sign-modal-head{
    z-index: 10;
    position: relative;
  }
  .sign-modal{
    margin: 10px;
  }

  .document-loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .sign-actions{
    flex: 0 1 50px;
    padding: 10px; 
    text-align: right; 
    border-top: 1px solid #8E8E8F26
  }

</style>
