<template>
  <hb-modal
    v-model="dialog"
    size="large"
    title="Add a Service"
    @close="$emit('close')"
    show-help-link
  >
    <template v-slot:subheader>
      <span>Add a new service to your tenant's account.</span>
    </template>

    <template v-slot:content>
      <hb-form label="Service Name" required>
        <v-select
          v-model="selectedService"
          :items="products"
          v-validate.disable="'required|min:1'"
          data-vv-scope="addService"
          item-text="name"
          id="selectedService"
          name="selectedService"
          :error="errors.collect('addService.selectedService').length > 0"
          label="Select a Service"
          single-line
          hide-details
          return-object
          clear-icon="mdi-close-circle"
        ></v-select>
      </hb-form>

      <hb-form class="start-service" multiple label="Start" required>
        <hb-radio-group v-model="serviceStart">
          <hb-radio
            label="Current Billing Period"
            value="current"
            :disabled="selectedService && selectedService==''"
          ></hb-radio>

          <span v-if="billingPeriods.future === undefined || !billingPeriods.future" >
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
          </span>
          <hb-radio v-else
            :label="`Next Billing Period: ${billingPeriods.future && $options.filters.formatDateServices(
              billingPeriods.future[0].start
            )} - ${billingPeriods.future && $options.filters.formatDateServices(billingPeriods.future[0].end)}`"
            value="next"
            :disabled="selectedService && (selectedService == '' || selectedService?.recurring === 0)"
          ></hb-radio>

        </hb-radio-group>
      </hb-form>

      <hb-form
        v-if="selectedService.recurring === 1"
        label="End"
        description="Please select the last service period where the service would be applied. If this range is left blank, the service will be billed continuously."
      >
        <v-select
          v-model="serviceEnd"
          :items="endBillingPeriods"
          data-vv-scope="addService"
          id="serviceEnd"
          name="serviceEnd"
          :error="errors.collect('addService.serviceEnd').length > 0"
          label="MMM DD, YYYY - MMM DD, YYYY"
          single-line
          hide-details
          :clearable="true"
          @click:clear="serviceEnd = null"
          clear-icon="mdi-close-circle"
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.start | formatDateServices }} -
            {{ data.item.end | formatDateServices }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.start | formatDateServices }} -
            {{ data.item.end | formatDateServices }}
          </template>
        </v-select>
      </hb-form>
      
      <v-row v-if="displayPaymentModule && !isNaionalAccount" class="ma-0 pa-5">
        <v-col cols="12" class="ma-0 pa-0">
          <payment-process
            :contactID="contact.id"
            :propertyID="lease.Unit.property_id"
            paymentSource="SERVICES"
          >
          </payment-process>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <hb-btn class="mr-2" v-if="displayPaymentModule && !isNaionalAccount && hasPermission('tenant_profile_add_service_skip_payment')" color="secondary" :disabled="disablePayment" @click="skipPayment">Skip Payment</hb-btn>
      <hb-tooltip v-else-if="!hasPermission('tenant_profile_add_service_skip_payment') && displayPaymentModule">
        <template v-slot:item>
          <hb-btn color="secondary" disabled>Skip Payment</hb-btn>
        </template>
        <template v-slot:body>
          {{ !hasPermission('tenant_profile_add_service_skip_payment') ? 'You do not have the permission to skip payment' : 'You do not have the permission for the payment' }}
        </template>
      </hb-tooltip>

      <hb-btn v-if="displayPaymentModule && !isNaionalAccount" color="primary" :loading="disablePayment" :disabled="isLoading($options.name) || disablePayment" @click="checkPaymentErrors">Process Payment</hb-btn>
      <hb-btn v-else color="primary" @click="saveService">Save Service</hb-btn>
    </template>
  </hb-modal>
</template>

<script type="text/babel">
import api from "../../assets/api.js";
import PaymentProcess from "../includes/PaymentProcess/Index.vue";
import Loader from '../assets/CircleSpinner.vue';
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "../../EventBus.js";
import { notificationMixin } from  '../../mixins/notificationMixin.js';
import axios from 'axios';
import https from 'https';

// Updated component for adding service
export default {
  name: "AddNewService",
  mixins: [ notificationMixin ],

  data() {
    return {
      products: [],
      billingPeriods: {},
      selectedService: "",
      serviceStart: "current",
      serviceEnd: null,
      invoicesToPay: [],
      disablePayment: false,
      isNaionalAccount: false
    };
  },

  props: {
    lease: {
      type: Object,
      required: true,
    },
    contact: {
      type: Object,
      required: true
    },
    showAddServiceModal: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  components: {
    PaymentProcess,
    Loader,
  },

  async created() {
    await this.fetchProducts();
    await this.fetchNextBillingPeriods();
    this.isNaionalAccount = this.contact?.NationalAccount?.id ? true : false;
    EventBus.$on('paymentReader', this.enablePayment);
  },

  destroyed() {
    EventBus.$off('paymentReader', this.enablePayment);
  },

  computed: {
    ...mapGetters({
      getLeases: 'paymentsStore/getLeases',
      hasPermission: "authenticationStore/rolePermission",
      getErrors: 'paymentsStore/getErrors'
    }),
    endBillingPeriods() {
      if (this.isCurrentServiceSelected) {
        let billingPeriods = [...this.billingPeriods.future];
        billingPeriods.unshift(this.billingPeriods.current);
        billingPeriods.pop();
        return billingPeriods;
      }
      return this.billingPeriods.future;
    },
    getInvoice() {
      return {
        selectedService: this.selectedService,
        serviceStart: this.serviceStart,
      };
    },
    isRecurringServiceSelected() {
      return this.selectedService.recurring === 1;
    },
    displayPaymentModule() {
      return this.selectedService && this.selectedService.id && (this.isCurrentServiceSelected || !this.isRecurringServiceSelected);
    },
    isCurrentServiceSelected() {
      return this.serviceStart === "current";
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },

  methods: {
    ...mapMutations({
      createLeaseStructure: "paymentsStore/createLeaseStructure",
      setLeaseBilledMonths: "paymentsStore/setLeaseBilledMonths",
      setCheckErrors: "paymentsStore/setCheckErrors",
    }),
    ...mapActions({
      getTransformedPaymentObject: "paymentsStore/getTransformedPaymentObject",
    }),
    checkPaymentErrors() {
      this.setCheckErrors({ value: true });
    },
    async fetchProducts() {
      try {
        const { property_id: propertyId } = this.lease.Unit;
        const res = await api.get(
          this,
          `${api.PROPERTIES}/${propertyId}/products?type=late&category_type=service`
        );
        this.products = res.products;
      } catch (err) {
        console.log('err ', err);
        this.showMessageNotification({ description: err });
      }
    },
    emitServiceAddedEvents() {
      this.showMessageNotification({ type: "success", description: 'Service Added' })
      EventBus.$emit('closeAddServiceModal');
      EventBus.$emit('fetchServices');
    },
    async fetchNextBillingPeriods() {
      try {
        const res = await api.get(
          this,
          `${api.LEASES}/${this.lease.id}/billing_periods?future_months=12`
        );
        this.billingPeriods = res.billing_months;
      } catch(err) {
        console.log('err ', err);
        this.showMessageNotification({ description: err });
      }
    },
    async saveService() {
      const validationStatus = await this.$validator.validateAll('addService');
      if(!validationStatus) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.'});
        return;
      };

      const product = this.formatProductData();
      try {
        await this.getOrSaveServiceDetails({
          product: product,
          save_service_only: true,
          property_id: this.lease.Unit.property_id
        });

        this.emitServiceAddedEvents();
      } catch (err) {
        this.showMessageNotification({ description: err });
      }
    },
    async skipPayment() {
      if (this.hasPermission('tenant_profile_add_service_skip_payment')){
        const product = this.formatProductData();
        try {
          await this.payInvoice({
            product: product,
            skipPayment: true
          });
          this.emitServiceAddedEvents();
        } catch (err) {
          this.showMessageNotification({ description: err })
        }
      }else{
        this.showMessageNotification({ type: 'error', description: 'You do not have the permission to skip payment' });
      }
    },
    async getOrSaveServiceDetails(serviceData) {
      const response = await api.post(this,`${api.LEASES}/${this.lease.id}/service-workflow`, serviceData);
      return response;
    },
    computeServiceStartDate() {
      return this.isCurrentServiceSelected ? this.billingPeriods.current.start : this.billingPeriods.future[0].start;
    },
    computeRecurringServiceDates() {
      const startDate = this.computeServiceStartDate();
      let endDate = null;

      if(this.serviceEnd) {
        endDate = this.serviceEnd.end;
      }

      return {
        start: startDate,
        end: endDate
      }
    },
    computeNonRecurringServiceDates() {
      const startDate = this.computeServiceStartDate();
      const endDate = startDate;

      return {
        start: startDate,
        end: endDate
      }
    },
    computeServiceDates() {
      const { recurring } = this.selectedService;

      if(recurring) {
        return this.computeRecurringServiceDates();
      }
      return this.computeNonRecurringServiceDates();
    },
    formatProductData() {
      const { name, price, recurring, prorate, prorate_out, description, id } = this.selectedService;
      const { start, end } = this.computeServiceDates();
        
      const product = {
        description: description,
        end_date: end,
        name: name,
        price: price,
        product_id: id,
        prorate_out: prorate_out,
        prorate: prorate,
        qty: 1,
        recurring: recurring,
        start_date: start
      };

      return product;
    },
    async fetchInvoice() {
      const response = await this.payInvoice({ 
        paymentInfo: null,
        skipPayment: null,
        dryRun: true 
      });

      this.invoicesToPay = [response.invoice];
      this.createLeaseStructure({
        invoices: this.invoicesToPay,
        lease: this.lease,
        unit: this.lease.Unit,
        updateInvoice: this.getLeases && this.getLeases.length
      });
      this.setLeaseBilledMonths({
        leaseIndex: 0,
        billedMonths: 0
      });
    },
    async payInvoice({ product, paymentInfo, skipPayment = false, dryRun = false }) {
      const serviceData = {
        product: product || this.formatProductData(),
        payment_details: paymentInfo,
        dryrun: dryRun,
        skip_payment: skipPayment,
        property_id: this.lease.Unit.property_id
      };
      
      const response = await this.getOrSaveServiceDetails(serviceData);
      return response;
    },
    async enablePayment(response) {
      this.disablePayment = false;
      if(response && response.error) {
        this.showMessageNotification({ description: response.error });
      } else if(response && response.data && response.data.msg) {
        this.showMessageNotification({ type: 'success', description: response.data.msg.text });
      }
    },
    async fetchPropertyConnections(device_id) {
              let r = await api.get(this.$app, api.PROPERTIES + this.lease.Unit.property_id + "/connections/address?device_id=" + device_id);
              this.IPaddress = r.IPaddress;
              this.lan = r.lan;
      },
    async processPayment() {
      let response = {};
      let payloadResponse;
      let lanResponseData;
      let payment_id;
      let responseData;
      this.disablePayment = true;
      const paymentInfo = await this.getTransformedPaymentObject({
        id: this.$options.name,
        formErrors: this.formErrors
      });

      if(paymentInfo.errors && paymentInfo.errors.length) {
        this.showMessageNotification({ list: paymentInfo.errors });
        this.disablePayment = false;
        return;
      }

      if(paymentInfo?.paymentMethod?.device_id){
        await this.fetchPropertyConnections(paymentInfo.paymentMethod.device_id);
      }
      try {
        if(paymentInfo.paymentMethod && paymentInfo.paymentMethod.device_id && this.lan == 1){
       

       // Create an instance of the HTTPS agent with certificate verification enabled.
             const certData = ``;
       
             const httpsAgent = new https.Agent({
             ca: [certData],
             });
       
             // Configure Axios to use the custom HTTPS agent.
             const instance = axios.create({
             httpsAgent
             });
             const endpoint = 'https://' + this.IPaddress + '?';
             //Get PAX Request Payload from HB Backend server
             payloadResponse = await api.post(this, api.PAYMENTS + "getLanSwipePayload",paymentInfo);
             payment_id = payloadResponse.payment.id;
             paymentInfo.payment.id = payment_id;
             // Make the GET request using the custom Axios instance.
             lanResponseData = await instance.get(endpoint + payloadResponse.payment.status_desc , {headers: {'Accept': 'text/plain',},});
             paymentInfo.payment.PAXResponse = lanResponseData;
             //console.log("lanResponseData :::",lanResponseData);
             responseData = await this.payInvoice({
                   lease_id: this.lease_id,
                   contact: this.contact,
                   payment: paymentInfo.payment,
                   paymentMethod: paymentInfo.paymentMethod,
                   InvoiceLines: this.generatedServices,
                   use_credits: paymentInfo.use_credits,
                   Invoices: paymentInfo.Invoices,
               }); 
            }else if(paymentInfo && paymentInfo.payment) {
              const responseData = await this.payInvoice({ paymentInfo: paymentInfo });
              EventBus.$emit('lease_edited');
              this.emitServiceAddedEvents();
              if(responseData && responseData.payment_id) { 
                EventBus.$emit('showPaymentDone', responseData.payment_id);
              }
            } 
          }catch(err){
            this.showMessageNotification({ description: err });
            if(paymentInfo.paymentMethod && paymentInfo.paymentMethod.device_id && this.lan == 1)
                    {
                      responseData = await api.post(this, api.PAYMENTS + payment_id + "/lanSwipeResponse", response);
                    }
        } finally {
          this.disablePayment = false;
        }                
      }         
    },

  watch: {
    async getInvoice() {
      if (this.displayPaymentModule) {
        await this.fetchInvoice();
        EventBus.$emit('updatePaymentApplication');
      }
    },
    getErrors(value) {
      if(value === false) {
        this.processPayment();
        this.setCheckErrors({ value: null });
      }
    },
    serviceStart(value){
      if(value === 'next' && this.selectedService != "" && !this.isRecurringServiceSelected) {
        this.showMessageNotification({ description: 'Non recurring services cannot be added to future billing cycles.' })
        this.serviceStart = 'current';
      }
    },
    async selectedService(value) {
      if (value && value?.recurring === 0) {
        if (this.serviceStart === 'next') {
          this.showMessageNotification({ description: 'Non recurring services cannot be added to future billing cycles.' })
          this.serviceStart = 'current';
        }
      }
    }
  }
};
</script>

<style>
.start-service .v-messages {
  min-height: 0px;
}
</style>
