<template>
  <div>
    <hb-modal
      v-model="dialog"
      title="Take a Payment"
      size="large"
      @close="$emit('close')"
      show-help-link
    >
      <template v-slot:content>
        <div class="pa-6 align-stretch hb-cloud-lighter">
          <payment-process
            :contactID="contact.id" 
            :propertyID="lease.Unit.property_id"
            paymentSource="BILL_DAY_CHANGE"
            >
          </payment-process>
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn class="mr-2" color="primary" @click="checkPaymentsErrors" :disabled="disable_payment" :loading="disable_payment">Process Payment</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import PaymentProcess from '../../includes/PaymentProcess/Index.vue';
import { EventBus } from '../../../EventBus.js';
import api from '../../../assets/api.js';
import moment from 'moment';
import axios from 'axios';
import https from 'https';

import { mapGetters, mapMutations, mapActions } from 'vuex';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';

export default {
  name: "BillingOnTakePaymentModal",
  mixins: [ notificationMixin ],
  data() {
    return {
      disable_payment: false,
    };
  },
  components:{
    PaymentProcess
  },
  props: ["value", "contact", "lease"],
  created() {
    EventBus.$on('paymentReader', this.enablePayment);
  },
  destroyed() {
    EventBus.$off('paymentReader', this.enablePayment);
  },
  computed: {
    ...mapGetters({
      getErrors: 'paymentsStore/getErrors'
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions({
      getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject'
    }),
    ...mapMutations({
      setPaymentSource: "paymentsStore/setPaymentSource",
      createLeaseStructure: "paymentsStore/createLeaseStructure",
      setLeaseBilledMonths: "paymentsStore/setLeaseBilledMonths",
      setCheckErrors: "paymentsStore/setCheckErrors",
      checkOnlyCredit: 'paymentsStore/checkOnlyCredit',
      setLeaseProperty: 'paymentsStore/setLeaseProperty',
      pushInvoices: 'paymentsStore/pushInvoices'
    }),
    checkPaymentsErrors() {
      //this.setCheckErrors({ value: true });
      //this function will check if the payment only with credit it will skip the checkErrors. otherwise, if not credit payment it wich check the errors normal.
      this.checkOnlyCredit();
    },
    async fetchPropertyConnections(device_id) {
              let r = await api.get(this.$app, api.PROPERTIES + this.lease.Unit.property_id + "/connections/address?device_id=" + device_id);
              this.IPaddress = r.IPaddress;
              this.lan = r.lan;
      },
    async processPayment() {
      let response = {};
      let payloadResponse;
      let lanResponseData;
      let payment_id;
      let responseData;
      this.disable_payment = true;
      const paymentInfo = await this.getTransformedPaymentObject({
        id: this.$options.name,
        formErrors: this.formErrors
      });

      if(paymentInfo.errors && paymentInfo.errors.length) {
        this.showMessageNotification({ list: paymentInfo.errors });
        this.disable_payment = false;
        return;
      }
      if(paymentInfo?.paymentMethod?.device_id){
        await this.fetchPropertyConnections(paymentInfo.paymentMethod.device_id);
      }
      try {
      if(paymentInfo.paymentMethod && paymentInfo.paymentMethod.device_id && this.lan == 1){
       

// Create an instance of the HTTPS agent with certificate verification enabled.
      const certData = ``;

      const httpsAgent = new https.Agent({
      ca: [certData],
      });

      // Configure Axios to use the custom HTTPS agent.
      const instance = axios.create({
      httpsAgent
      });
      const endpoint = 'https://' + this.IPaddress + '?';
      //Get PAX Request Payload from HB Backend server
      payloadResponse = await api.post(this, api.PAYMENTS + "getLanSwipePayload",paymentInfo);
      payment_id = payloadResponse.payment.id;
      paymentInfo.payment.id = payment_id;
      // Make the GET request using the custom Axios instance.
      lanResponseData = await instance.get(endpoint + payloadResponse.payment.status_desc , {headers: {'Accept': 'text/plain',},});
      paymentInfo.payment.PAXResponse = lanResponseData;
      //console.log("lanResponseData :::",lanResponseData);
      responseData = await api.post(this, `${api.LEASES}/${this.lease.id}/change-bill-day`,{
            lease_id: this.lease_id,
            contact: this.contact,
            payment: paymentInfo.payment,
            paymentMethod: paymentInfo.paymentMethod,
            InvoiceLines: this.generatedServices,
            use_credits: paymentInfo.use_credits,
            Invoices: paymentInfo.Invoices,
            new_bill_day: this.lease.bill_day,
            new_invoice_send_day: this.lease.invoice_send_day,
        });
      } 
      else if(paymentInfo && paymentInfo.payment) {
       
           responseData = await this.payInvoice({paymentInfo: paymentInfo});
            response = {
              data: {
                msg: {
                  text: `The billing day has been updated to ${this.$options.filters.dayEnding(this.lease.bill_day)} of the month and the payment was processed on ${moment().format('MMM Do, YYYY @ h:mma')}.`
                }
              }
            };
          }
        } catch(err){
          response = {
            error: err,
            contact_id: paymentInfo.contact_id
          };
          if(paymentInfo.paymentMethod && paymentInfo.paymentMethod.device_id && this.lan == 1)
                  {
                    responseData = await api.post(this, api.PAYMENTS + payment_id + "/lanSwipeResponse", response);
                  }
        } finally {
          this.enablePayment(response);
        }                
                    
    },
    async enablePayment(response) {
      this.disable_payment = false;
      this.$emit('close');
      if(response && response.error) {
        this.showMessageNotification({ type: 'error', description: response.error });
      } else if(response && response.data && response.data.msg) {
        this.showMessageNotification({ type: 'success', description: response.data.msg.text });
        EventBus.$emit('activeEdit', '');
        EventBus.$emit('reload_tenant_profile_leases');
      }
    },
    async payInvoice({ paymentInfo, dryRun = false }) {
      const billingOnData = {
        new_bill_day: this.lease.bill_day,
        new_invoice_send_day: this.lease.invoice_send_day,
        payment_details: paymentInfo,
        dryrun: dryRun
      };
      try {
        const response = await api.post(this, `${api.LEASES}${this.lease.id}/change-bill-day`, billingOnData);
        return response;
      } catch (err) {
        throw err;
      }
    },
  },
  watch: {
    getErrors(value) {
      if(value === false) {
        this.processPayment();
        this.setCheckErrors({ value: null });
      }
    }
  }

}
</script>

<style lang="scss" scoped>
</style>