<template>
        
    
    <div class="section-content pt-4" :class="{'mt-10' : $vuetify.breakpoint.xs , 'mt-n3' : $vuetify.breakpoint.sm}">
            <hb-header
                :divider="false"
            >
                <template v-slot:left>

                    <hb-autocomplete
                        single-line
                        rounded
                        placeholder="Select"
                        :items="['Today','Past Week','Past Month','All Time']"
                        v-model="period"
                        @change="fetchData()"
                        width="370px"
                        style="display:inline-block"
                    ></hb-autocomplete>
                </template>
                <template v-slot:right>
                    <div>
                        <hb-text-field
                            @change="searchSelect"
                            search
                            v-model="searchField"
                            width="370px"
                        ></hb-text-field>
                    </div>
                    <div>
                        <hb-btn
                            class="ml-1"
                            icon
                            tooltip="Filters"
                            :active="showMasterFilterModal"
                            @click="showMasterFilter"
                            >mdi-filter-variant</hb-btn>
                    </div>
                </template>
            </hb-header>
                <GetFilter v-if="showMasterFilterModal" @DRFilters="filter" @close="showMasterFilterModal=false">
                    
                </GetFilter> 
    
                <hb-data-table
                    :headers="headers"
                    :items="items.refunds"
                    :loading="loadingDocuments"
                    loading-text="Loading Documents..."
                    fixed-header
                    hide-default-footer
                    class="corporate-merchand-table"
                    :class="'frozen-column-table-property'"
                >
                <template class="disabled-row" v-slot:item.direct_refund_id="{ item }">
                    {{item.id }}
                </template>
                <template   class="d-flex, disabled-row" v-slot:item.request_date="{ item }">
                    {{item.request_date | formatDateServices }}
                </template>
                <template v-slot:item.property_name="{ item }">
                    {{item.property_name}}
                </template>
                <template  v-slot:item.space_num="{ item }">
                    <hb-icon color="#101318" small class="pr-1"
                        mdi-icon="mdi-facility-custom-3"></hb-icon> {{item.space_num}}
                </template>
                <template v-slot:item.amount="{ item }">
                    {{"$ " + item.amount}}
                </template>
                <template v-slot:item.refund_types="{ item }">
                    <span v-if="item.invoices_payment_id">Rentals</span>
                    <span v-else>Merchandise</span>
                </template>
                <template v-slot:item.Payment_method="{ item }">
                    <span v-if="item.Payment_method=='directdebit'">Direct Debit</span>
                    <span v-if="item.Payment_method=='eftpos'"> EFTPOS </span>
                    <span v-if="item.Payment_method=='cash'">Cash</span>
                    
                </template>
                <template v-slot:item.since_last_payment="{ item }">
                    {{calculateDateDifference(item.request_date) + " Days"}}
                </template>
                <template v-slot:item.ref_name="{ item }">
                    {{item.ref_name}}
                </template>
                <template v-slot:item.move_out_date="{ item }">
                    {{item.move_out_date | formatDateServices}}
                </template>
                <template v-slot:item.status="{ item }">
                    <span v-if="item.status=='approval_pending'" class= "hb-font-caption-medium" ><hb-icon color="#FFD600" small class="pr-1"
                        mdi-icon="mdi-alert-octagon"></hb-icon> Approval Pending</span>
                    <span v-else-if="item.status=='approved'" class= "hb-font-caption-medium"><hb-icon color="#02AD0F" small class="pr-1"
                        mdi-icon="mdi-check-circle"></hb-icon> Approved</span>
                    <span v-else-if="item.status=='reject'" class= "hb-font-caption-medium"  style="color: #FB4C4C"><hb-icon color="#FB4C4C" small class="pr-1"
                        mdi-icon="mdi-alert"></hb-icon> Rejected</span>
                    <span v-else-if="item.status=='refund_rejected'" class= "hb-font-caption-medium"  style="color: #FB4C4C"><hb-icon color="#FB4C4C" small class="pr-1"
                        mdi-icon="mdi-alert"></hb-icon> Bank Rejected</span>
                    <span v-else-if="item.status=='processed'" class= "hb-font-caption-medium"><hb-icon color="#3D8FFF" small class="pr-1"
                        mdi-icon="mdi-check-circle"></hb-icon> Processed</span>
                    
                </template>
                <template v-slot:item.status_details="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-hover v-slot:default="{ hover }">
                                <span v-on="on" v-if="item.status_details[0]" style="color: #0288D1; cursor: pointer; ">
                                    Control Check
                                </span>
                            </v-hover>
                        </template>
                        <span v-if="JSON.stringify(item.status_details).includes('1')">Control 1 - Account match found in Employee's Payroll Details.<br></span>
                        <span v-if="JSON.stringify(item.status_details).includes('2')">Control 2- Refund initiated beyond 30 days from the date of move-out.<br></span>
                        <span v-if="JSON.stringify(item.status_details).includes('3')">Control 3- Bank account no. matches previously used refund bank account by different tenant<br></span>
                        <span v-if="JSON.stringify(item.status_details).includes('4')">Control 4- Refund initiated for a move-out has lease balance not equal to 0<br></span>
                        <span v-if="JSON.stringify(item.status_details).includes('5')">Control 5- Refund Amount Exceeds $500.<br></span>
                        <span v-if="JSON.stringify(item.status_details).includes('6')">Control 6- Lease is still active</span>
                    </v-tooltip>
                </template>

                <!-- Reject Reason -->
                <template v-slot:item.reject_reason="{ item }">
                    <v-tooltip bottom v-if="item.status==='reject'" >
                        <template v-slot:activator="{ on }">
                            <v-hover v-slot:default="{ hover }">
                                <span v-on="on" style="color: #0288D1; cursor: pointer; ">
                                    View Reason
                                </span>
                            </v-hover>
                        </template>
                        <span>{{ item.reject_reason || 'No reason provided' }}</span>
                    </v-tooltip>
                </template>

                <template v-slot:item.actions="{ item }">
                    <hb-menu options class="d-flex justify-center" v-if="item.status=='approved' || item.status=='approval_pending'">
                        <v-list v-if="item.status=='approval_pending'">

                            <v-list-item :ripple="false" @click="setStatus('approved', item.id)">
                                <v-list-item-title>Approve</v-list-item-title>
                            </v-list-item>

                            <v-list-item :ripple="false" @click="openRejectDialog(item.id)">
                                <v-list-item-title>Reject</v-list-item-title>
                            </v-list-item>

                        </v-list>
                        <v-list v-if="item.status=='approved'">

                            <v-list-item :ripple="false" @click="setStatus('processed', item.id , item.payment_id, item.amount, item.invoices_payment_id)">
                                <v-list-item-title>Process</v-list-item-title>
                                    </v-list-item>

                                <v-list-item :ripple="false" @click="openRejectDialog(item.id)">
                                <v-list-item-title>Reject</v-list-item-title>
                            </v-list-item>

                        </v-list>
                    </hb-menu>
                </template>
                
                </hb-data-table>
                   
                
                <display-reject 
                    v-if="showRejectDialog" 
                    :refund_id="selectedItemId" 
                    :showRejectReason="showRejectDialog"  
                    @submit-reject-reason="handleRejectReason"
                    @close="showRejectDialog = false" 
                />
                
                <v-divider v-if="infinityLoader"></v-divider>
                <div class="d-flex align-center justify-center" v-if="infinityLoader">
                    <v-progress-circular indeterminate width="2" size="24" class="mx-0 pa-0 my-4" color="primary"></v-progress-circular>
                </div>
    </div>
</template>

<script type="text/babel">
    import HbDatePicker from '../assets/HummingbirdDatepicker';
    import { mapGetters, mapActions } from 'vuex';
    import Loader from '../assets/CircleSpinner.vue';
    import { notificationMixin } from '../../mixins/notificationMixin.js';
    import api from '../../assets/api.js';
    import GetFilter from './Getfilter.vue'; 
    import moment from 'moment';
    import DisplayReject from './DisplayReject.vue';

    export default {
        name: "FilesList",
        mixins: [ notificationMixin ],
        data() {
            return {
                todaysDate: '',
                showMasterFilterModal: false,
                showRejectDialog: false, 
                selectedItemId: null,
                loadingDocuments: false,
                filters:'',
                searchField: '',
                headers: [
                    { text: 'Refund No', value: 'number', width: "130px"},
                    { text: 'Request Date', value: 'request_date', width: "130px" },
                    { text: 'Space Number', value: 'space_num', width: "150px" },
                    { text: 'Amount', value: 'amount', width: "100px" },
                    { text: 'Property', value: 'property_name', width: "200px" },
                    { text: 'Type', value: 'refund_types', width: "130px" },
                    { text: 'Payment Method', value: 'Payment_method', width: "200px"},
                    { text: 'Days Since Payment', value: 'since_last_payment', width: "250px" },
                    { text: 'Name', value: 'ref_name', width: "150px" },
                    { text: 'Move Out', value: 'move_out_date', width: "150px" },
                    { text: 'Refund Status', value: 'status', width: "100px" },
                    { text: 'Control Check', value: 'status_details', width: "150px" },
                    { text: 'Reject Reason', value: 'reject_reason', width: "150px" },
                    { text: '', value: 'actions', align: 'right', sortable: false, width: "50px" },
                ],
                property: '',
                items: [],
                infinityLoader: false,
                currentPage: 0,
                period: '',
                totalPages: 0,
                payment_id:''
            }
        },
        components:{
            HbDatePicker,
            GetFilter,
            DisplayReject
        },
        mounted() {
        },
     async created (){
       
        await this.fetchData()
        },
        computed:{
          ...mapGetters({
              isAdmin: 'authenticationStore/isAdmin',
              properties: 'propertiesStore/filtered',
              getUserData: 'authenticationStore/getUserData'
          }),
          
        },
        methods:{
		    ...mapActions({
			    reversePayment: 'paymentsStore/reversePayment',
                setReversal: "paymentsStore/setReversal",
		    }),

            async fetchData(){
                if(this.period){
                    await api.get(this, api.DIRECT_REFUNDS, {period : this.period} ).then(r => {
                console.log("period ", this.period)
                this.items = r;       
                });
                }
                else {
                    await api.get(this, api.DIRECT_REFUNDS).then(r => {
                        this.items = r;      
                });
                this.setDrawerHeight();
                }
            },
            setDrawerHeight() {
            let documentHeight = document.body.clientHeight;
            let tableElement = document.getElementsByClassName('corporate-merchand-table');
            if (tableElement && tableElement[0]) {
                let tableTop = tableElement[0].getBoundingClientRect().top;
                this.drawerHeight = `${Math.ceil((documentHeight - tableTop) - 11)}px`;
                this.$emit('set-sidebar-height', documentHeight - tableTop);
                this.tableHeight = `${Math.ceil((documentHeight - tableTop) - 78)}px`;
                const innerElement = tableElement[0].querySelector(".v-data-table__wrapper");
                innerElement.style.maxHeight = this.tableHeight;
                 }
            },
            async searchSelect(){
                let period = {
                                endDate : this.filters.endDate ?? '', 
                                property_id : this.filters.property ?? '',
                                startDate : this.filters.startDate ?? '',
                                status : this.filters.status ?? '', 
                                searchField : this.searchField ?? ''
                } 
                await api.get(this, api.DIRECT_REFUNDS, period ).then(r => {
                    this.items = r;       
                });


            },
            calculateDateDifference(date) {
                const given = moment(date);
                const current = moment();
                return current.diff(given, 'days');
            },
            showMasterFilter() {
                this.showMasterFilterModal = true;
            },
            onCloseDialog() {
                this.$emit("close");
            },
            async setStatus(status, id, payment_id, amount, invoices_payment_id){
                if(status==="processed"){
                    this.payment_id = payment_id
                    if(!this.payment_id) return;
                    let r = await api.get(this, api.GET_PAYMENT + this.payment_id + "?unit_info=true");

                    var invoices = [];

                    for(var i=0; i<r.payment.AppliedPayments.length; i++) {
                        const refundAmount =r.payment.AppliedPayments[i].amount;

                        
                        if(r.payment.AppliedPayments[i].id == invoices_payment_id){
                                invoices.push({
                                    invoices_payment_id: r.payment.AppliedPayments[i].id,
                                    amount: amount,
                                    invoice_amount: r.payment.AppliedPayments[i].amount,
                        });
                    }


                      
                    }
                    
                    let data= {
                        amount: amount,
                        invoices: invoices ,
                        is_prepay: false,
                        reason:' ',
                        reversal_type: "directrefund",
                        direct_refund_id: id
                    }

                    try{  
                    let response = await api.post(this, api.GET_PAYMENT + this.payment_id + "/refund", data);
                    await api.post(this, api.DIRECT_REFUNDS+ 'update_status' , {refund_status: status, id: id , payment_id: payment_id} )
                    }catch(error){ 
                        this.showMessageNotification({ description : error });
                    }    
                }else{     
                    await api.post(this, api.DIRECT_REFUNDS+ 'update_status' , {refund_status: status, id: id , payment_id: payment_id} )
               }
 
                await this.fetchData()

            },

            openRejectDialog(id) {
                this.selectedItemId = id;
                this.showRejectDialog = true;
            },
            async handleRejectReason(reject_reason) {
                try {
                await api.post(this, api.DIRECT_REFUNDS+ 'update_status' , {refund_status: 'reject', id: this.selectedItemId } );

                await api.post(this, api.DIRECT_REFUNDS+ 'reject_reason' , {id: this.selectedItemId, reject_reason: reject_reason} );
                this.showMessageNotification({type: 'success', description: "You have rejected a direct refund application."});       
                
                this.showRejectDialog = false; 
                this.fetchData();
                } catch (error) {
                    this.showMessageNotification({ description : error });
                }
            },
            async filter(filters) {
                try {
                this.filters=filters
                let period = {  period : 'custom',
                                endDate : filters.endDate,
                                property_id : filters.property,
                                startDate : filters.startDate,
                                status : filters.status 
                } 
                await api.get(this, api.DIRECT_REFUNDS, period ).then(r => {
                    this.items = r;       
                });
                this.showMasterFilterModal=false
                } catch (error) {
                    this.showMessageNotification({ description : error });
                }
            },


        },
        watch: {
            
        }
    }



</script>

<style lang="scss" scoped>
.no-data-box {
    padding: 30px 0;
    text-align: center;
    border: 1.5px dashed #ccc;
    border-radius: 3px;
}

.cm-footer {
    border: thin solid rgba(0, 0, 0, 0.12);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.merchandise-drawer {
    height: 100%;
    top: 36px;
    transform: translateX(0%);
    z-index: 11;
    width: 50%;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

.top-2px {
    position: relative;
    top: 2px;
}

.button-option {
    border: 1px solid #C4CDD5;
    border-radius: 4px;
    background-color: #FFF;
}

.button-option .btn-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.btn-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.bulk-edit-item {
    background-color: #FFF;
    border-bottom: 1px solid #e1e6eb;
}
.bulk-buttons {
    margin-bottom: 80px;
}
</style>

<style lang="scss">
.property-settings-merchandise .hb-form-edit-mode-main-padding {
    padding-bottom: 20px;
}

.download-report-form .row.no-gutters {
    height: 100%;
}
.frozen-column-table-property {
  table {
    > thead, > tbody {
      > tr {
        /* Styling for the first four columns and last column */
        th:first-child,
        td:first-child,
        th:nth-child(2),
        td:nth-child(2),
        th:nth-child(3),
        td:nth-child(3),
        th:nth-child(4),
        td:nth-child(4),
        th:last-child,
        td:last-child {
          position: sticky !important;
          position: -webkit-sticky !important;
          z-index: 3 !important;
          background: white;
        }

        /* Ensure correct left positioning based on actual widths */
        th:first-child,
        td:first-child {
          left: 0 !important;
          width: 100px; /* Adjust if necessary */
          min-width: 100px;
          max-width: 100px;
        }

        th:nth-child(2),
        td:nth-child(2) {
          left: 100px !important;
          width: 120px; /* Adjust if necessary */
          min-width: 120px;
          max-width: 120px;
        }

        th:nth-child(3),
        td:nth-child(3) {
          left: 220px !important; /* 100px (1st) + 120px (2nd) */
          width: 130px; /* Adjust if necessary */
          min-width: 130px;
          max-width: 130px;
        }

        th:nth-child(4),
        td:nth-child(4) {
          left: 350px !important; /* 100px (1st) + 120px (2nd) + 130px (3rd) */
          width: 140px; /* Adjust if necessary */
          min-width: 140px;
          max-width: 140px;

          /* Add separator for clarity */
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 2px;
            background: rgba(0, 0, 0, 0.1);
            box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
            pointer-events: none;
          }
        }

        /* Freeze the last column */
        th:last-child,
        td:last-child {
          right: 0 !important;
          width: 45px;
          min-width: 45px;
          max-width: 45px;

          /* Add left border to separate it */
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 2px;
            background: rgba(0, 0, 0, 0.1);
            box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
            pointer-events: none;
          }
        }

        /* Adjust z-index for header cells */
        th:first-child,
        th:nth-child(2),
        th:nth-child(3),
        th:nth-child(4),
        th:last-child {
          z-index: 4 !important;
        }
      }
    }
  }
}



</style>
