<template>
  <div v-resize="onResize" style="background-color: #F9FAFB;" class="chat-window-container" :class="{'fill-height':adjustLayout}">
    <v-card
      v-if="Object.keys(contactFullData).length && !hideHeader && !noHeader && (!(isInteractionActive && $vuetify.breakpoint.width <= 800))"
      flat
      tile
      class="chat-window-header pa-0 ma-0 pl-5"
      :class="[layout === 'message-center' ? 'communication-center-style' : chatWindowHeight === 'x-large' ?'py-3':'py-5']"
       style="background-color: #F9FAFB;"
    >
      <!-- in order to display access codes correctly, we have to add logic to check if vendor is space access based or regular, so I am removing the display of access codes for now because old logic was not fully correct -->
      <mini-profile-view 
        v-if="contactFullData?.id && !InteractionObj.isNational"
        :contact-id="contactFullData.id"
        :use-contact="false"
        :contact-data="InteractionObj.isNational ? {...contactFullData, isNational: InteractionObj.isNational} : contactFullData"
      />
      <hb-mini-profile v-else type="account" :title="InteractionObj.national_account_name" :status="InteractionObj.status ? InteractionObj.status : undefined
              " :address="computedBusinessAddress" :account-space-count="calculateLength"
                :account-space-type="contactFullData.Leases && contactFullData.Leases?.length === 1 ? contactFullData?.Leases[0]?.Unit?.type : '' " :account-size="accountSize"
                :account-total-monthly-spent="NationalAccount.total_monthly_rent | formatMoney" />
    </v-card>
    <v-divider v-if="!hideHeader && !noHeader"></v-divider>
    <status class="chat-error" @resetStatus="clearChatError" v-if='chatError' :message="chatError" status="error"></status>
    <v-divider v-if='chatError'></v-divider>
    <v-sheet  v-if="!isInteractionActive" class="chat-window-nav">
      <v-row no-gutters class="pb-0 ml-0 mb-3" :class="{'actionable-header': $vuetify.breakpoint.xs, 'pl-0 pt-0' : fromTenantProfile, 'pl-0 pt-0': from == 'national_account', 'pl-4 pt-5 pr-4' : !fromTenantProfile && from != 'national_account'}" style="background-color: #F9FAFB;  display: flex; align-items: baseline;">
      <div    :style="dynamicStyle"  class="action-1">
                  <HbSelect
                  box
                  :items="menuOptions"
                  item-text="label"
                  item-value="key"
                  :clearable="false"
                    background-color="white"
                    outlined
                    hide-details
                    dense
                    v-model="dropdownValue"
                    height="40"
                    attach
                    />
                </div>
      <div v-if="this.dropdownValue" class="space-selection" :class="{ 'ml-4': from !== 'moveOut' }">
        <space-selection-dropdown :mainViewOptions="mainViewOptions" :contact="contact" :propertyView="currentPropertyId" @spaceSelectionChanged="onSelectedSpace" :isNational="InteractionObj.isNational && hasPermission('manage_national_accounts')">
                        </space-selection-dropdown>
                </div>
                <hb-menu  options v-if="shouldRenderComponent">
                    <v-list>
                        <v-list-item :ripple="false" @click="downloadReport" :disabled="downloadButton">
                          <v-list-item-icon class="mr-3 mb-0 mt-2">
                              <hb-icon>mdi-download</hb-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Download</v-list-item-title>
                        </v-list-item>
                        <v-list-item :ripple="false" @click="openFilterModal">
                          <v-list-item-icon class="mr-3 mb-0 mt-2">
                              <hb-icon>mdi-filter-variant</hb-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Filter</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    </hb-menu>
                    <div v-else>
                <HbBtn icon tooltip="Download" mdi-code="mdi-download" :active-state-off="true" :disabled="downloadButton" class="ml-2" @click="downloadReport"/> 
                <!-- <HbBtn icon tooltip="Search" mdi-code="mdi-magnify" /> -->
                <HbBtn type="icon" active-state-off tooltip="Filter" :active="filterModal" @click="openFilterModal" >
                  <template >
                    <v-badge
                      color="#F26500"
                      dot
                      :value="isFilterActive"
                    >
                      <hb-icon
                      >mdi-filter-variant</hb-icon>
                    </v-badge>
                  </template>
                </HbBtn> 
                
                <v-spacer></v-spacer>
              </div>
        <hb-menu :disabled="contactFullData && Object.keys(contactFullData).length === 0 && from == 'national_account'">
          <template v-slot:menu-activator>
           
            <hb-btn v-if="!dense" color="primary" small  class="mx-0" style="margin-top: 5px;" :disabled="contactFullData && Object.keys(contactFullData).length === 0 && from == 'national_account'">
              <hb-icon medium color="#FFFFFF">mdi-plus</hb-icon></hb-btn>
            <hb-btn v-else color="primary" small class="mx-3 d-flex align-center mdi-plus-mobile"  style="margin-top: 10px;" :disabled="contactFullData && Object.keys(contactFullData).length === 0 && from == 'national_account'">
              <hb-icon small color="#FFFFFF">mdi-plus</hb-icon>
            </hb-btn>
          </template>
          <v-list>
            <v-list-item @click="onEmailShow">
              <v-list-item-icon class="mr-3 mb-0 mt-2">
                <hb-icon>mdi-email</hb-icon>
              </v-list-item-icon>
              <v-list-item-title>Send Email</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onSmsShow">
              <v-list-item-icon class="mr-3 mb-0 mt-2">
                <hb-icon>mdi-message-processing</hb-icon>
              </v-list-item-icon>
              <v-list-item-title>Send Text</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onPhoneShow">
              <v-list-item-icon class="mr-3 mb-0 mt-2">
                <hb-icon>mdi-phone</hb-icon>
              </v-list-item-icon>
              <v-list-item-title>Log Phone Call</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onNoteShow">
              <v-list-item-icon class="mr-3 mb-0 mt-2">
                <hb-icon>mdi-clipboard-outline</hb-icon>
              </v-list-item-icon>
              <v-list-item-title>Add Note</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onMakePhoneCall" v-if="isOutBoundCallPermitted">
              <v-list-item-icon class="mr-3 mb-0 mt-2">
                <hb-icon>mdi-phone-outgoing</hb-icon>
              </v-list-item-icon>
              <v-list-item-title>Make Phone Call</v-list-item-title>
            </v-list-item>
          </v-list>
        </hb-menu>
    </v-row>
  </v-sheet>
    <!-- header Action Bar -->
    <v-row no-gutters v-if="isInteractionActive" class="interaction-action-bar mx-0 py-1 compose-title-bar">
      <v-col cols="12" class="pa-0">
        <div class="font-weight-medium subtitle-1 ml-4 d-flex align-center">
          <!-- <span><v-icon size="24" style="vertical-align: middle">mdi-reply</v-icon> {{SendCommuncationText}}</span> -->
          <span v-if="showEmail"><v-icon v-if="this.replyToMessage" size="24" style="vertical-align: middle">mdi-reply</v-icon> {{this.replyToMessage ? "Reply to Message": "Send Email"}}</span>
          <span v-if="showSms"><v-icon size="24" style="vertical-align: middle">mdi-reply</v-icon> {{this.replyToMessage ? "Reply to Message": "Send Text"}}</span>
          <span v-if="showPhone"><v-icon size="24" style="vertical-align: middle">mdi-reply</v-icon> {{this.replyToMessage ? "Reply to Message": "Log Phone Call"}}</span>
          <span v-if="showNote"><v-icon size="24" style="vertical-align: middle">mdi-content-paste</v-icon> {{this.replyToMessage ? "Reply to Message": "Add Note"}}</span>
          <v-spacer></v-spacer>
          <span class="pr-1">
            <hb-btn icon tooltip="Close" @click="closeInteraction()">mdi-close</hb-btn>
          </span>
        </div>

      </v-col>
    </v-row>
    <v-col class="pa-0 for-space-anchor">
    <v-row  no-gutters >
      <!-- <div class="ml-2">
      <HbTextField
      style="width: 370px;"
          search
          placeholder="Search"
          @input="search"
          v-model="commSearch"
          clearable
          @click:clear="clear"
        />
                </div> -->
    <div v-if="showNotesFilter" ref="child1" style="width:calc(100% - 75%); min-width: 213px;"  class="ml-4 ">
<!-- TO AVOID BUG BELOW COMMENT IS NOT ADDED 
TODO : FIX THIS
-->
      <!-- :value="noteCatagories[0]" -->
      <HbSelect
          class="mb-2 mt-2" 
          box
          v-validate="'required|max:45'"
          :value="noteCatagories[0]"
          :items="noteCatagories"
          data-vv-scope="default"
          id="type"
          name="type"
          @input="getFilterNotes"
          :clearable="false"
          />
    </div>
    <div v-if="isInteractionActive && showNote && mailNotes === false" style="width:calc(100% - 75%);"  class="ml-4 ">
          <space-selection-dropdown  class="mb-2 mt-2" :setSelection="setSelectionFlag" :contact="contact" :mainViewOptions="mainViewOptions" @spaceSelectionChanged="onSelectedSpaceChanged" @setDefaultSpaceValue="onSelectedSpaceChanged">
          </space-selection-dropdown>
    </div>
     </v-row>
    </v-col>
    <div v-if="adjustLayout" ref="chatList" :class="{'scroll-shadow':hasScrollShadow && !isInteractionActive}" class="pa-0 ma-0 fill-height" @scroll.self="fetchOnScroll" style="position: relative;overflow-y: auto;">
      <v-row v-if="!isInteractionActive" class="chat-list-container" no-gutters>
        <v-col class="pb-0">
          <div class="chat-list" >
            <v-card elevation="0" style="background-color: #F9FAFB;" class="mx-auto" tile>
              <v-list dense class="pt-0 pb-0" :class="{'pl-0 pr-0' : fromTenantProfile, 'pl-0 pr-0': from == 'national_account', 'pl-5 pr-5' : !fromTenantProfile && from != 'national_account'}">
                <div
                  no-gutters
                  v-for="(r, i) in filteredChatListRefresh"
                  :key="'multiple_sync_' + i"
                  :id="`interaction_id_${i}`"
                  class="mb-2 justify-center position-relative"
                  >
                  
                  <hb-communication
                    :class="{'isPinned':r.pinned === 1 ? true : false, 'isRead': hbCommunicationData(r)?.type === 'note' ? false : !r.read }"
                    :active="i == activeIndex"
                    @click="markStatus(r, i)"
                    :elevation="i == activeIndex"
                    :expanded.sync="r.expanded"
                    :type="hbCommunicationData(r)?.type"
                    :system-generated="hbCommunicationData(r)?.systemGenerated"
                    :audit-trail-date-time="hbCommunicationData(r)?.time ? hbDateFormat(hbCommunicationData(r)?.time) : ''"
                    :space="hbCommunicationData(r)?.space"
                    :space-type="hbCommunicationData(r)?.space && hbCommunicationData(r)?.space != 'Tenant' ? 'storage' : undefined"
                    :time="hbDateFormat(r.created)"
                    :unread="hbCommunicationData(r)?.type === 'note' ? false : !r.read"
                    :pinned="r.pinned === 1 ? true : false"
                    :playback-url="hbCommunicationData(r)?.playbackUrl"
                    :voicemail-url="hbCommunicationData(r)?.voicemailUrl"
					          :property="r.DeliveryMethod?.delivery_type === 'phone_call' ? r.property_name : ''"
                    :status="hbCommunicationData(r)?.status"
                    :status-time="hbCommunicationData(r)?.statusTime ? hbDateFormat(hbCommunicationData(r)?.statusTime) : ''"
                    :subject="r?.Email?.subject"
                    :source="hbCommunicationData(r)?.source"
                    :has-attachments="r?.Upload?.[0]?.id ? true : false"
                    :admin-name="hbCommunicationData(r)?.adminName"
                    :hide-resend-button="true"
                    :delivery-method="hbCommunicationData(r)?.delivery_method ?? ''"
                    :document-name="hbCommunicationData(r)?.document_name ?? ''"
                    :delivered-by="hbCommunicationData(r)?.delivered_by ?? ''"
                    :has-notes="false"
                    :hide-view-print-button="false"
                    :alternate-contact="hbAlternateData(r)?.status"
                    :alternate-contact-id="hbAlternateData(r)?.contact_id"
                    :alternate-contact-type="hbAlternateData(r)?.contact_type"
                    :alternate-contact-name="hbAlternateData(r)?.contact_name"
                    :alternate-contact-email="hbAlternateData(r)?.contact_email"
                    :alternate-contact-phone="hbAlternateData(r)?.contact_phone"
                    :hide-call-back-button="!isOutBoundCallPermitted"
                    :note-category="r?.notes_category ?? 'Miscellaneous' "
                    :error-description="hbCommunicationData(r)?.description"
                    :hide-error-description="false"
                    @pinned-icon-clicked="changePinStatus(r,i)"
                    @reply-email="onMessageShow(r, r?.Email?.subject)"
                    @reply-text="onMessageShow(r, r?.Email?.subject)"
                    @call-back="onMakePhoneCall"
                    @add-notes="onNoteShow(r)"
                    @width-change="handleWidthChange"
                    @view-print="downloadfile(r?.Upload[0])"
                  >
                  <iframe v-if="r.iframe" class="mail-container-iframe" @load="(event) => adjustIframeHeight(event, r)" :src="r.iframe" style="width:100%;height: 35px" frameborder="0"></iframe>
                  <div v-else v-html="checkforMMScontent(r.content, r)" class="chat-window-hb-communication-default-slot-wrapper"></div>
                  <template v-slot:leftActions v-if="r.Upload[0]?.id && r.DeliveryMethod?.delivery_type != 'mail' && r.DeliveryMethod?.gds_key != 'registered_email' ? true : false">
                    <v-row no-gutters v-if="r.Upload.length > 2 && !r.showMoreAttachments">
                      <hb-menu v-for="(attachment, i) in r.Upload.slice(0, 2)" :key="'attachment' + i">
                            <template v-slot:menu-activator>
                                <hb-chip pointer :max-width="communicationWidthHalved">
                                  {{ docName(r.Upload[i])}}
                                </hb-chip>
                            </template>

                            <v-list>
                                <v-list-item :href="r.Upload[i].src" target="_blank">
                                    <v-list-item-title>View / Print</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="downloadfile(r.Upload[i])">
                                  <v-list-item-title>
                                      Download
                                  </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                        <hb-chip pointer @chip-click="r.showMoreAttachments = true" :max-width="communicationWidthHalved">
                + {{ r.Upload.length - 2 }} more
            </hb-chip>
                    </v-row>
                    <v-row no-gutters v-else>
                      <hb-menu v-for="(attachment, i) in r.Upload" :key="'attachment' + i">
                            <template v-slot:menu-activator>
                                <hb-chip pointer :max-width="communicationWidthHalved">
                                  {{ docName(r.Upload[i])}}
                                </hb-chip>
                            </template>

                            <v-list>
                                <v-list-item :href="r.Upload[i].src" target="_blank">
                                    <v-list-item-title>View / Print</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="downloadfile(r.Upload[i])">
                                  <v-list-item-title>
                                      Download
                                  </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                       
                    </v-row>
                </template>
                </hb-communication>
                </div>
              </v-list>
              <v-row class="mx-0" v-if="!firstTimeLoad && !filteredChatList.length && !isLoading($options.name)">
                  <v-col md="12" class="text-center">
                      No communication found
                  </v-col>
              </v-row>
              <v-row class="mx-0" v-if="firstTimeLoad || isLoading($options.name)">
                <v-col md="12" class="text-center">
                  <v-skeleton-loader
                        type="list-item-avatar-two-line, divider, table-heading, list-item-two-line, table-heading, list-item-two-line,table-heading, list-item-two-line"
                        :elevation="3"
                    ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
           

      <v-row v-else class="send-email-template" no-gutters>
        <v-col class="ma-0 pa-0 interaction-window">
          <send-email
            :to='contact.email'
            subject=''
			      :spaceListViewOptions='this.mainViewOptions'
            v-if="showEmail && !replyToMessage"
            :onClose='onEmailClose'
            :interactiveMode="isInteractionActive"
            @interaction_saved="onEmailClose"
            :onChatError='onChatError'
            :contactId="InteractionId"
            :textarea_height="textAreaHeight"
            :contactEmail="contactEmailList"
            :leases="leasesDoc"
            :propertyid="currentPropertyId"
            :properties ="properties"
            :property_id="interaction_property_id"
            :usage-context="usageContext"
            :emailWindowIdentifier="sectionIdentifier"
          >
          </send-email>
		  <send-email
            :to='contact.email'
            :subject='emailSubject'
            :Message='mailContent'
            :mailOldContent='mailOldContent'
            :replyToMessage='replyToMessage'
			      :spaceListViewOptions='this.mainViewOptions'
            v-if="showEmail && replyToMessage"
            :onClose='onEmailClose'
            :interactiveMode="isInteractionActive"
            @interaction_saved="onEmailClose"
            :onChatError='onChatError'
            :contactId="InteractionId"
            :textarea_height="textAreaHeight"
            :contactEmail="contactEmailList"
            :leases="leasesDoc"
            :propertyid="currentPropertyId"
            :properties ="properties"
            :property_id="interaction_property_id"
            :usage-context="usageContext"
            :emailWindowIdentifier="sectionIdentifier"
          >
          </send-email>
          <send-sms
            :to="(contact.Phones && contact.Phones.length) ? contact.Phones[0].phone : ''"
			      :spaceListViewOptions='this.mainViewOptions'
             v-if="showSms"
            :interactiveMode="isInteractionActive"
            :onClose='onSmsClose'
            :onChatError='onChatError'
            :contactId="InteractionId"
            :phoneId="(contact.Phones && contact.Phones.length) ? contact.Phones[0]?.id : ''"
            :textarea_height="textAreaHeight"
            :contactPhoneList="this.contactPhoneList"
            :property_id="interaction_property_id"
            :properties ="properties"
            :usage-context="usageContext"
            :smsWindowIdentifier="sectionIdentifier"
                      >
          </send-sms>
          <add-note
            v-if="showNote"
			      :spaceListViewOptions='this.mainViewOptions'
            :interactiveMode="isInteractionActive"
            :onClose='onNoteClose'
            :onChatError='onChatError'
            :contactId="InteractionId"
            :context="context"
            :textarea_height="textAreaHeight"
            :notesCategoryId="notesCategoryId"
            :noteCategoryRef="noteCategoryRef"
            :mailNotes="mailNotes"
            :errorCheck="errorCheck"
            :noteInteractionId="noteInteractionId"
            @updateNoteCategories="updateNoteCategories"
            :leases="leasesDoc"
            :propertyid="currentPropertyId"
            :usage-context="usageContext"
            :spaceListView = "spaceListView"
          >
          </add-note>
           <add-phone
            v-if="showPhone"
            :interactiveMode="isInteractionActive"
            :onClose='onPhoneClose'
            :onChatError='onChatError'
            :contactId="InteractionId"
            :context="context"
            :textarea_height="textAreaHeight"
            :property_id="(currentPropertyId && this.currentPropertyId != 'all-property') ? currentPropertyId : interaction_property_id"
            :usage-context="usageContext"
          >
          </add-phone>

        </v-col>
      </v-row>
    </div>
    <!-- Needs to optimise -->
    <div v-else class="pa-0 ma-0">
      <v-row no-gutters>
        <v-col v-if="!isInteractionActive" class="pb-0">
          <div style="height:612px;overflow-y: auto;" ref="chatList" class="chat-list" :class="{'scroll-shadow':hasScrollShadow}"  @scroll.self="fetchOnScroll">
            <v-card elevation="0" style="background-color: #F9FAFB;" class="mx-auto" tile>
              <v-list dense class="pt-0 pb-0" :class="fromTenantProfile ? 'pl-0 pr-0' : 'pl-4 pr-4'">
                <div
                  no-gutters
                  v-for="(r, i) in filteredChatListRefresh"
                  :key="'multiple_sync_' + i"
                  :id="`interaction_id_${i}`"
                  class="mb-2 justify-center position-relative"
                  >
                  
                  <hb-communication
                    :class="{'isPinned':r.pinned === 1 ? true : false, 'isRead': hbCommunicationData(r)?.type === 'note' ? false : !r.read }"
                    :active="i == activeIndex"
                    @click="markStatus(r, i)"
                    :elevation="i == activeIndex"
                    :expanded.sync="r.expanded"
                    :type="hbCommunicationData(r)?.type"
                    :system-generated="hbCommunicationData(r)?.systemGenerated"
                    :audit-trail-date-time="hbCommunicationData(r)?.time ? hbDateFormat(hbCommunicationData(r)?.time) : ''"
                    :space="hbCommunicationData(r)?.space"
                    :time="hbDateFormat(r.created)"
                    :unread="hbCommunicationData(r)?.type === 'note' ? false : !r.read"
                    :pinned="r.pinned === 1 ? true : false"
                    :playback-url="hbCommunicationData(r)?.playbackUrl"
                    :voicemail-url="hbCommunicationData(r)?.voicemailUrl"
					          :property="r.DeliveryMethod?.delivery_type === 'phone_call' ? r.property_name : ''"
                    :status="hbCommunicationData(r)?.status"
                    :status-time="hbCommunicationData(r)?.statusTime ? hbDateFormat(hbCommunicationData(r)?.statusTime) : ''"
                    :subject="r?.Email?.subject"
                    :source="hbCommunicationData(r)?.source"
                    :has-attachments="r.Upload[0]?.id ? true : false"
                    :admin-name="hbCommunicationData(r)?.adminName"
                    :hide-resend-button="true"
                    :delivery-method="hbCommunicationData(r)?.delivery_method ?? ''"
                    :document-name="hbCommunicationData(r)?.document_name ?? ''"
                    :delivered-by="hbCommunicationData(r)?.delivered_by ?? ''"
                    :has-notes="false"
                    :hide-view-print-button="false"
                    :alternate-contact="hbAlternateData(r)?.status"
                    :alternate-contact-id="hbAlternateData(r)?.contact_id"
                    :alternate-contact-type="hbAlternateData(r)?.contact_type"
                    :alternate-contact-name="hbAlternateData(r)?.contact_name"
                    :alternate-contact-email="hbAlternateData(r)?.contact_email"
                    :alternate-contact-phone="hbAlternateData(r)?.contact_phone"
                    :hide-call-back-button="!isOutBoundCallPermitted"
                    :note-category="r?.notes_category ?? 'Miscellaneous' "
                    :error-description="hbCommunicationData(r)?.description"
                    :hide-error-description="false"
                    @pinned-icon-clicked="changePinStatus(r,i)"
                    @reply-email="onMessageShow(r, r?.Email?.subject)"
                    @reply-text="onMessageShow(r, r?.Email?.subject)"
                    @call-back="onMakePhoneCall"
                    @add-notes="onNoteShow(r)"
                    @width-change="handleWidthChange"
                    @view-print="downloadfile(r?.Upload[0])"
                  >
                  <iframe v-if="r.iframe" class="mail-container-iframe" @load="(event) => adjustIframeHeight(event, r)" :src="r.iframe" style="width:100%;height: 35px" frameborder="0"></iframe>
                  <div v-else v-html="checkforMMScontent(r.content, r)" class="chat-window-hb-communication-default-slot-wrapper"></div>
                  <template v-slot:leftActions v-if="r.Upload[0]?.id && r.DeliveryMethod?.delivery_type != 'mail' && r.DeliveryMethod?.gds_key != 'registered_email' ? true : false">
                    <v-row no-gutters v-if="r.Upload.length > 2 && !r.showMoreAttachments">
                      <hb-menu v-for="(attachment, i) in r.Upload.slice(0, 2)" :key="'attachment' + i">
                            <template v-slot:menu-activator>
                                <hb-chip pointer :max-width="communicationWidthHalved">
                                  {{ docName(r.Upload[i])}}
                                </hb-chip>
                            </template>

                            <v-list>
                                <v-list-item :href="r.Upload[i].src" target="_blank">
                                    <v-list-item-title>View / Print</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="downloadfile(r.Upload[i])">
                                  <v-list-item-title>
                                      Download
                                  </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                        <hb-chip pointer @chip-click="r.showMoreAttachments = true" :max-width="communicationWidthHalved">
                + {{ r.Upload.length - 2 }} more
            </hb-chip>
                    </v-row>
                    <v-row no-gutters v-else>
                      <hb-menu v-for="(attachment, i) in r.Upload" :key="'attachment' + i">
                            <template v-slot:menu-activator>
                                <hb-chip pointer :max-width="communicationWidthHalved">
                                  {{ docName(r.Upload[i])}}
                                </hb-chip>
                            </template>

                            <v-list>
                                <v-list-item :href="r.Upload[i].src" target="_blank">
                                    <v-list-item-title>View / Print</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="downloadfile(r.Upload[i])">
                                  <v-list-item-title>
                                      Download
                                  </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                       
                    </v-row>
                </template>
                </hb-communication>
                </div>
              <v-row class="mx-0" v-if="!firstTimeLoad && !filteredChatList.length && !isLoading($options.name)">
                  <v-col md="12" class="text-center">
                      No communication found
                  </v-col>
              </v-row>
              <v-row class="mx-0" v-if="firstTimeLoad || isLoading($options.name)">
                <v-col md="12" class="text-center">
                  <v-skeleton-loader
                    type="list-item-avatar-two-line, divider, table-heading, list-item-two-line, table-heading, list-item-two-line,table-heading, list-item-two-line"
                    :elevation="3"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-list>
            </v-card>
          </div>
        </v-col>
      </v-row>
           

      <v-row no-gutters>
        <v-col v-if="isInteractionActive" class="ma-0 pa-0 interaction-window">
          <send-email
            :to='contact.email'
            subject=''
			      :spaceListViewOptions='this.mainViewOptions'
            v-if="showEmail && !replyToMessage"
            :onClose='onEmailClose'
            :interactiveMode="isInteractionActive"
            @interaction_saved="onEmailClose"
            :onChatError='onChatError'
            :contactId="InteractionId"
            :textarea_height="textAreaHeight"
            :contactEmail="contactEmailList"
            :leases="leasesDoc"
            :propertyid="currentPropertyId"
            :properties ="properties"
            :property_id="interaction_property_id"
            :national-account="from == 'national_account'"
            :usage-context="usageContext"
            :emailWindowIdentifier="sectionIdentifier"
          >
          </send-email>
		  <send-email
            :to='contact.email'
            :subject='emailSubject'
            :Message='mailContent'
            :mailOldContent='mailOldContent'
            :replyToMessage='replyToMessage'
			      :spaceListViewOptions='this.mainViewOptions'
            v-if="showEmail && replyToMessage"
            :onClose='onEmailClose'
            :interactiveMode="isInteractionActive"
            @interaction_saved="onEmailClose"
            :onChatError='onChatError'
            :contactId="InteractionId"
            :textarea_height="textAreaHeight"
            :contactEmail="contactEmailList"
            :leases="leasesDoc"
            :propertyid="currentPropertyId"
            :properties ="properties"
            :property_id="interaction_property_id"
            :national-account="from == 'national_account'"
            :usage-context="usageContext"
            :emailWindowIdentifier="sectionIdentifier"
          >
          </send-email>
          <send-sms
            :to="(contact.Phones && contact.Phones.length) ? contact.Phones[0].phone : ''"
			      :spaceListViewOptions='this.mainViewOptions'
             v-if="showSms"
            :interactiveMode="isInteractionActive"
            :onClose='onSmsClose'
            :onChatError='onChatError'
            :contactId="InteractionId"
            :phoneId="(contact.Phones && contact.Phones.length) ? contact.Phones[0]?.id : ''"
            :textarea_height="textAreaHeight"
            :contactPhoneList="this.contactPhoneList"
            :property_id="interaction_property_id"
            :properties ="properties"
            :usage-context="usageContext"
            :smsWindowIdentifier="sectionIdentifier"
                      >
          </send-sms>
          <add-note
            v-if="showNote"
			      :spaceListViewOptions='this.mainViewOptions'
            :interactiveMode="isInteractionActive"
            :onClose='onNoteClose'
            :onChatError='onChatError'
            :contactId="InteractionId"
            :context="context"
            :textarea_height="textAreaHeight"
            :notesCategoryId="notesCategoryId"
            :noteCategoryRef="noteCategoryRef"
            :mailNotes="mailNotes"
            :errorCheck="errorCheck"
            :noteInteractionId="noteInteractionId"
            @updateNoteCategories="updateNoteCategories"
            :leases="leasesDoc"
            :propertyid="currentPropertyId"
            :usage-context="usageContext"
            :spaceListView = "spaceListView"
          >
          </add-note>
           <add-phone
            v-if="showPhone"
            :interactiveMode="isInteractionActive"
            :onClose='onPhoneClose'
            :onChatError='onChatError'
            :contactId="InteractionId"
            :context="context"
            :textarea_height="textAreaHeight"
            :property_id="(currentPropertyId && this.currentPropertyId != 'all-property') ? currentPropertyId : interaction_property_id"
            :usage-context="usageContext"
          >
          </add-phone>

        </v-col>
      </v-row>
    </div>
     <select-action
        v-if="showSelectActionModal"
        :showSelectActionModal="showSelectActionModal"
        @close="showSelectActionModal = false"
        @confirmClicked="actionConfirmed"
     ></select-action> 
     <hb-modal title="Filter" v-model="filterModal" :footerCancelOption="false" @close="onCloseModal()">
          <template v-slot:subheader>
           Filter your communications here.
          </template>
            <template v-slot:content>
                <hb-form label="Read/Unread" full>
                  <HbCheckbox
                  v-model="filterData.read"
                  label="Read"
                  />
                  <HbCheckbox
                  v-model="filterData.unread"
                  label="Unread"
                  />
                </hb-form>
                <hb-form label="Date range" full>
                  <div class="mt-4 mb-2">
                    <HbSelect
                    v-model="filterData.selectedDay"
                    :items="['Today', 'Yesterday', 'Custom Range']"
                    v-validate="'required'"
                    data-vv-scope="default"
                    data-vv-name="timeframe"
                    data-vv-as="timeframe"
                    :error="errors.has('default.timeframe')"
                    placeholder="Custom Timeframe"
                />
                  <v-row v-if="filterData.selectedDay === 'Custom Range'">
                    <v-col>
                      <HbDatePicker
                      v-model="filterData.start_date"
                      @click:clear="filterData.start_date = null"
                      v-validate="'required'"
                      label="Start date"
                      :clearable="true"
                      clear-icon="mdi-close-circle"
                      :solo="false"
                      :single-line="true"
                      data-vv-scope="general"
                      data-vv-name="start_date"
                      name="start_date"
                      data-vv-as="Start date"
                      dense
                      :error="errors.collect('general.start_date').length > 0"
                  />
                    </v-col>
                    <v-col>
                      <HbDatePicker
                      v-model="filterData.end_date"
                      @click:clear="filterData.end_date = null"
                      v-validate="'required'"
                      label="End date"
                      :clearable="true"
                      :min="filterData?.start_date || date"
                      clear-icon="mdi-close-circle"
                      :solo="false"
                      :single-line="true"
                      data-vv-name="end_date"
                      data-vv-as="End date"
                      :error="errors.collect('general.end_date').length > 0"
                      dense
                      default_today
                      :disabled="!filterData.start_date"
                  />
                    </v-col>
                  </v-row>
              </div>
            </hb-form>
        
            </template>
          <template v-slot:right-actions>
            <hb-link color="tertiary" @click="clearForm()">Clear Filters</hb-link>
            <hb-btn @click="applyFilter()" :loading="isFilterLoading" :disabled="!isButtonEnable">Apply</hb-btn>
        </template>
      </hb-modal>

  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import methods from '../../assets/api/methods'
import api from '../../assets/api.js';
import addNote from './AddNote'
import addPhoneCall from './AddPhoneCall'
import { EventBus } from '../../EventBus.js';
import moment from 'moment';
import Status from '../includes/Messages.vue';
import CharmNotification from '../../models/CharmNotification';
import SelectAction from '../charm/SelectAction.vue';
import HbDatePicker from '../assets/HummingbirdDatepicker.vue';
import { parsePhoneNumber } from 'libphonenumber-js';
import { notificationMixin } from "../../mixins/notificationMixin";
import SpaceSelectionDropdown from '../settings/spaceSelectionDropdown/spaceSelectionDropdown.vue';
import MiniProfileView from '../includes/LeadIntake/MiniProfileView.vue';


export default {
  name:'ChatWindow',
  data () {
    return {
      mailContent:"",
      mailOldContent:"",
      leasesDoc:"",
      leasesDocAll:"",
      commSearch:"",
      dropdownValue:"all",
      isInteractionActive: false,
      isFilterVisible:true,
      threadStyle:{
        height: '350px',
      },
      firstTimeLoad:true,
      activeTab: '',
      contact: {
        salutation: '',
        first: '',
        middle: '',
        last: '',
        suffix: '',
        status: '',
        email: '',
        Phones: [
          { phone:''}
        ]
      },
      menuOptions:[
      { label: 'All Communications', key: 'all' },
        { label: 'Email', key: 'email' },
        { label: 'Text', key: 'sms' },
        { label: 'Phone', key: 'phone' },
        { label: 'Mailings', key: 'mail' },
        { label: 'Notes', key: 'note' },
      ],
      mainViewOptions:[],
      mainView: {
        id: null,
        Unit: {
          number: 'Tenant',
          type: 'tenant',
        }
      },
      attatchments: [],
      ChatObj: {},
      filteredChatList: [],
      ChatList: [],
      showEmail: false,
      showSms: false,
      showNote: false,
      showPhone: false,
      InteractionId: '',
      textAreaHeight: 82,
      context: 'communicationCenter',
      chatError: '',
      isAllRecordsLoaded: false,
      replyToMessage: false,
      emailSubject: '',
      showSelectActionModal:false,
      outBoundCallPropertyId: null,
      contactNotificationObject:null,
      activeIndex: 0,
      expanded: false,
      noteCatagories:[],
      notesCategoryId:'',
      errorCheck: false,
      mailNotes: false,
      noteInteractionId: '',
      isAddNoteInForeground:false,
      showNotesFilter:false,
      download: true,
      getFilterNotesTriggerFlag:false,
      contactEmailList:[],
      contactPhoneList:[],
      contactFullData:[],
      communicationWidthHalved:'',
      scrollForFirstTime: true,
      filterModal: false,    // filter modal popup
      filterData :{
        start_date: '',
        end_date: '',
        selectedDay : '',
        read: '',
        unread: ''
      },
      InitialfilterData :{
        start_date: '',
        end_date: '',
        selectedDay : '',
        read: '',
        unread: ''
      },
      isFilterLoading: false,
      noteCategoryRef:'',
      interaction_property_id:'',
      interaction_contact_id:'',
      downloadButton: false,
      uiBreakPoint: 1520,
      mailContentBlobUrls: [],
      iframeLoadHistory: {},
      hasScrollShadow:false,
      tenant_contact: {},
      setSelectionFlag:false,
      spaceListView: {
        id: null,
        Unit: {
          number: 'Tenant',
          type: 'tenant',
          property_id:''
        }
      },
    }
  },
  props: {
        InteractionObj: {
      type: Object
    },
    adjustLayout: {
      type: Boolean,
      default:false
    },
    dense: {
      type: Boolean
    },
    hideHeader: {
        type:Boolean
    },
    chatWindowHeight: {
      type: String
    },
    noHeader: {
      type: Boolean
    },
    currentPropertyId:{
      type: String,
      default: ""
    },
    layout:{
      type:String,
      default:""
    },
    activeReservations:{
      type:Array,
      default:() => []
    },
    blade_data: {
      type: Object,
      default:() => {}
    },
    from :{
      type: String,
      default: ""
    },
    fromTenantProfile: {
        type: Boolean,
        default: false
    },
    sectionIdentifier:{
       type: String,
       default: ""
    },
    isUnifiedCommunication:{
      type: Boolean,
      default: false
    }
  },
  components: {
    'send-email': () => import('./SendEmail'),
    'send-sms': () => import('./SendSms'),
    'add-note': addNote,
    'add-phone':addPhoneCall,
    Status,
    SelectAction,
    HbDatePicker,
    SpaceSelectionDropdown,
    MiniProfileView
  },
  mixins: [notificationMixin],
  computed:{
    ...mapGetters({
    NationalAccount: 'onBoardingStore/getNationalAccount',
    properties: 'propertiesStore/filtered',
    loggedInUser: "authenticationStore/getUserData",
    isCharmSubscribedProperty: 'charmSubscriptionStore/isCharmSubscribedProperty',
    hasPermission: 'authenticationStore/rolePermission',
    hasCharmSubscribedPropertySelected: 'charmSubscriptionStore/hasCharmSubscribedPropertySelected',
    callWrapAction:"charmCallStore/callWrapData"
    }),
    shouldRenderComponent() {
    const isSmallScreen = this.$vuetify.breakpoint.width < this.uiBreakPoint;
    const isMoveInOrLeadFollowUp = this.from === 'moveIn' || this.from === 'lead_follow_up';

    return isSmallScreen || isMoveInOrLeadFollowUp;
  },
    isButtonEnable() {
      if (this.filterData) {
        if (this.filterData.selectedDay === 'Custom Range') {
          if (this.filterData.start_date && this.filterData.end_date)
            return true;
          else
            return false
        }
        else if (this.filterData.selectedDay) {
          return true
        }
        else if (this.filterData.read || this.filterData.unread ){
          return true;
        }  
        else
          return false;
      }
    },
    usageContext() {
      const { from, fromTenantProfile, layout } = this
      return from === "national_account"
        ? "nationalAccount"
        : fromTenantProfile
        ? "tenantProfile"
        : layout === "message-center"
        ? "unifiedCommunications"
        : ""
    },
    isOutBoundCallPermitted() {
    return !!this.outBoundCallPropertyId && this.hasPermission('mini_charm');
    },
    computedContactTitle() {
      return (this.contact?.first ? this.contact?.first : '') + (this.contact?.middle ? ' ' + this.contact?.middle : '') + (this.contact?.last ? ' ' + this.contact?.last : '');
    },
    computedContactPhone() {
      if(this.contact.Phones && this.contact.Phones[0] && this.contact.Phones[0].phone){
        return this.contact.Phones[0].phone;
      } else {
        return undefined;
      }
    },
    computedContactPhoneVerified() {
      if(this.contact.Phones && this.contact.Phones[0] && this.contact.Phones[0].phone_verified && this.contact.Phones[0].phone_verified == 1){
          return true;
      } else {
          return false;
      }
    },
    computedContactAddress() {
      if(this.contact.Addresses && this.contact.Addresses[0] && this.contact.Addresses[0].Address){
        const address_1 = this.contact.Addresses[0].Address.address ? this.contact.Addresses[0].Address.address : '';
        const address_2 = this.contact.Addresses[0].Address.address2 ? this.contact.Addresses[0].Address.address2 : '';

        let address = address_1;
        if(address_2 != '') address += ' ' + address_2;
        
        address += ', ' + this.$options.filters.formatAddress(this.contact.Addresses[0].Address); 
        
        return address;
      } else {
        return undefined;
      }
    },
    interactionsCount() {
      if(this.dropdownValue === 'note'){
        return this.filteredChatList.length
      }else{
        return this.filteredChatList.length ? this.filteredChatList.filter(item => item.DeliveryMethod).length: 0;  
      }
    },
    isFilterActive() {
      // Check if any of the filter criteria are set
      return Object.values(this.filterData).some(value => value !== '')
    },
    dynamicStyle(){
      return "flex:1"
    },
    computedRepresentativeName() {
        if (this.contact.Representative) {
          return (this.contact?.Representative?.Contact?.first ? this.contact?.Representative?.Contact?.first : '') + (this.contact?.Representative?.Contact?.last ? ' ' + this.contact?.Representative?.Contact?.last : '');
        }
        return undefined;
    },
    filteredChatListRefresh() {
      this.revokeAllBlobURLs();
      return this.filteredChatList.map((r, i) => {
        if(r && r.DeliveryMethod?.delivery_type === 'email') {
          const styledContent = `
            <html>
              <head>
                <style>
                  body {
                    -ms-overflow-style: none !important; /* Internet Explorer */
                    ${ this.hbCommunicationData(r)?.type == 'email out' ? 'font-family: "Graphik Web", sans-serif;font-size: 12px;color: #637381;-webkit-font-smoothing:antialiased;line-height: 16px;': ''}
                  }
                  body::-webkit-scrollbar { 
                    display: none !important;  /* Safari and Chrome */
                  }
                </style>
              </head>
              <body>
                ${r.content}
              </body>
            </html>
          `;
          // Create a Blob object from the HTML content
          const blob = new Blob([styledContent], { type: 'text/html' });
          // Generate a Blob URL
          const blobUrl = URL.createObjectURL(blob);

          this.mailContentBlobUrls.push(blobUrl);
          // console.log("blobUrl", `'${blobUrl}'`, this.hbCommunicationData(r)?.type);
          // Return an iframe with the Blob URL as the source
          r.iframe = blobUrl;
          r.iframeLoaded = false;
        }
        return r;
      })
    },
    computedBusinessAddress() {
      if (
        this.contactFullData.Business &&
        this.contactFullData.Business.Address
      ) {
        const address_1 = this.contactFullData.Business.Address.address
          ? this.contactFullData.Business.Address.address
          : "";
        const address_2 = this.contactFullData.Business.Address.address2
          ? this.contactFullData.Business.Address.address2
          : "";

        let address = address_1;
        if (address_2 !== "") {
          if (address !== "") {
            address += ", ";
          }
          address += address_2;
        }

        if (address !== "") {
          address += ", ";
        }

        address += this.$options.filters.formatAddress(this.contactFullData.Business.Address);

        return address;
      } else {
        return undefined;
      }
      },
    calculateLength() {
      let leases = this.contactFullData?.Leases?.filter(lease => !lease.end_date)
      return leases?.filter((lease) => {
        return this.filterLeases(lease.Unit.property_id);
      })?.length;
    },
    accountSize() {
      if (this.NationalAccount?.total_sqm) {
        return `${this.NationalAccount.total_sqm} sqm`;
      }
      return '';
    },
  },
  watch:{
    isInteractionActive(value){
      this.hasScrollShadow = false
      let chatList = this.$refs['chatList']
      if(value){
        chatList?.removeEventListener('scroll', this.handleScroll);
      }else{
        this.$nextTick(()=>{
          chatList?.addEventListener('scroll', this.handleScroll);
        })
      }
      this.emitInteraction(value)
    },
    dropdownValue () {
      this.filteredChatList = [];
      this.getInteractionDetails();
    },
    async InteractionObj () {
      this.resetAllData()
      this.contactNotificationObject = {}
      this.contact = this.InteractionObj;
      // if(this.InteractionObj.isNational){
      // this.getNationalAccountInfo();
      // }
      this.interaction_contact_id = this.InteractionObj.id
      this.syncContactObj(this.contact)
      
      
      this.InteractionId = this.InteractionObj?.id;
      this.isInteractionActive = false;
      
      this.getContactAccess();
      this.getInteractionDetails(true);
      if(this.layout == "message-center" || this.layout == "leads-list") {
        this.setOutBoundCallProperty();
      }
      
      this.scrollForFirstTime = true
    },
    
    filteredChatList () {
      //this.updateItemCount();
    },
    async currentPropertyId() {
      this.setOutBoundCallProperty();
      this.contact = this.InteractionObj;
      this.syncContactObj(this.contact)
      this.getInteractionDetails(true);
    },
    callWrapAction:{
      handler() {
        if(this.callWrapAction){
          this.callEvent(this.callWrapAction);
          this.setChatDimensions();
          this.setCallWrapAction('');
        }
      },
      deep: true
    },
    blade_data: {
      handler() {
        if (this.blade_data.action) {
          setTimeout(() => {
            this.callEvent(this.blade_data.action);
            this.setChatDimensions();
            this.$emit('updateBladeData', {});
          }, 1000);
        }
      },  
      deep: true
    },

  },
  async created () {
    this.setSelectionFlag = this.mainViewOptions.length > 1
    this.iframeLoadHistory = {};
    this.contact = this.InteractionObj;
    this.interaction_contact_id = this.InteractionObj.id;
    this.InteractionId = this.InteractionObj?.id;
    
    this.syncContactObj(this.contact)
    this.getContactAccess();
    this.setChatDimensions();
    this.setOutBoundCallProperty();
    EventBus.$on('communication::refresh', this.getNewMessages);
    EventBus.$on('contact_modal_go_email', this.onEmailShow);
    EventBus.$on('onNoteShow',this.onNoteShow);
    EventBus.$on('onEmailShow',this.onEmailShow);
    
    EventBus.$on('onSmsShow',this.onSmsShow);
    EventBus.$on('onSetUnknown',this.onSetUnknown);
    EventBus.$on('tenant_updated', this.setContactDetail);
  },
  mounted() {
  // this.getNationalAccountInfo();
    this.setChatDimensions();
    if (this.blade_data?.action) {
        this.callEvent(this.blade_data.action);
        this.setChatDimensions();
        this.$emit('updateBladeData', {});
    }
    
    if (this.currentPropertyId != 'all-property') { // because based on this spaceSelectionChanged event getInteractionDetails() will call 
      this.getInteractionDetails(true)
    }
    let chatList = this.$refs['chatList']
    chatList.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy(){
      let chatList = this.$refs['chatList']
      chatList.removeEventListener('scroll', this.handleScroll)
  },
  destroyed(){
    EventBus.$off('communication::refresh', this.getNewMessages);
    EventBus.$off('contact_modal_go_email', this.onEmailShow);
    EventBus.$off('onNoteShow')
    EventBus.$off('onEmailShow')
    EventBus.$off('onSmsShow')
    EventBus.$off('onSetUnknown')
    EventBus.$off('tenant_updated');
    this.revokeAllBlobURLs();
    EventBus.$emit('hideMiniprofile', false);
    this.$emit('onInteraction', false)
  },
  methods: {
    onSelectedSpaceChanged(spaceNumber){
      this.spaceListView = spaceNumber
    },
  // async getNationalAccountInfo(){
  //   let r = await api.get(this, api.CONTACTS + this.contact.id);
  //   this.tenant_contact = r.contact;
  // },
  filterLeases(property_id) {
      return this.properties.some((i) => i.id === property_id);
    },
    handleScroll() {
                let chatList = this.$refs['chatList']
                const scrollPosition = chatList.scrollTop;
                this.hasScrollShadow = scrollPosition > 0;
    },
    onResize(){
      this.emitInteraction(this.isInteractionActive)
    },
    emitInteraction(value){
      const shouldHideMiniprofile = (this.fromTenantProfile || this.from === 'national_account') && value
      EventBus.$emit('hideMiniprofile', shouldHideMiniprofile)
      this.$emit('onInteraction', value)
    },
    adjustIframeHeight(event, r) {
      this.$nextTick(() => {
        const iframe = event.target
        
        if (iframe && iframe.contentWindow) {
          iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
        }
      });
    },
    openFilterModal() {
      this.filterModal = true
      this.InitialfilterData = {
        start_date: this.filterData.start_date,
        end_date: this.filterData.end_date,
        selectedDay: this.filterData.selectedDay,
        read: this.filterData.read,
        unread: this.filterData.unread
      }
    },
    async downloadReport() {
      this.downloadButton = true;
      let format = 'pdf'
      let timeZone = moment.tz.guess();
      let data;
      this.showMessageNotification({
        type: 'document_download', 
        title: 'Generating Export',
        loading: true,
        timeout: 5000,
        description: 'We are generating your document, it should begin downloading shortly.'
      });
      if (this.mainView.Unit.number == 'Tenant' || this.mainView.Unit.number == '') {
        data = {
          type: 'interaction',
          format: format,
          contact_id: this.interaction_contact_id,
          property_id: this.interaction_property_id,
          timeZone: timeZone,
          name: 'Communication History',
        };
      }else{
        data = {
          type: 'interaction',
          format: format,
          contact_id: this.interaction_contact_id,
          property_id: this.interaction_property_id,
          timeZone: timeZone,
          name: 'Communication History',
          unit_number: this.mainView.Unit.number,
        };
      }
      try {
        let response = await api.post(
          this,
          api.REPORTS + "download-pdf",
          data,
        );
        var buffer = new Uint8Array(response.data);
        var blob = new Blob([buffer], { type: "application/pdf" });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'CommunicationHistory.pdf';
        link.click();
      } catch (err) {
        this.showMessageNotification({ description: err })
      } finally {
        this.downloadButton = false;
      }
    },
    onCloseModal() {
      this.filterModal = false
      this.filterData = this.InitialfilterData
    },
  async clearForm() {
      this.filterData.start_date = ''
      this.filterData.end_date = ''
      this.filterData.selectedDay = ''
      this.filterData.read = ''
      this.filterData.unread = ''
      await this.getInteractionDetails(true);
      this.filterModal = false
    },
    async applyFilter() {
      if (this.filterData) {
        this.isFilterLoading = true
        try {
          // api filter
          await this.getInteractionDetails(true);
        } catch (error) {
          this.isFilterLoading = false;
        } finally {
          this.isFilterLoading = false;
        }
        this.filterModal = false
      }
    },
    docName(upload){
      let name = upload.name ? upload.name : upload.DocumentType.name ? upload.DocumentType.name : "attachment"
      let src  = upload.src
      if(name && src){
        const parts = src.split(".");
      const fileExtension = parts.length > 1 ? parts.pop().toLowerCase() : ''
          if (fileExtension.length < 5) {
            if(!name.endsWith(`.${fileExtension}`)){
                return  `${name}.${fileExtension}`
            }else{
               return name
               }
          } else {
            return name 
          }
      }
    },
    ...mapActions({
      makeOutboundCall:'charmCallStore/makeOutboundCall',
      setCallWrapAction:'charmCallStore/setDatacallWrap',
    }),
    ...mapMutations({	
      setContact: 'onBoardingStore/setContact'	
    }),	
    setContactDetail(contact){	
       this.contact = contact;
    },

    async syncContactObj(contactObj) {
      let contactId = this.$route.params.contact_id;
      if(this.interaction_contact_id) contactId = this.interaction_contact_id;
      // move up since response is needed for all emails and phone numbers
      let response = await api.get(this, api.CONTACTS + contactId + "/profile-info?communication=1");
      this.contactFullData = response.contact;
      // set Email list from response
      this.setEmailList(response.contact)
      // set phone list from response
      this.setPhoneList(response.contact)
      // set propertyId
      this.getPropertyIdForInteractions(response.contact)
      this.getNoteCatagories(this.interaction_property_id)
      this.resetSpaceMenuOptions(response.contact);
    }
,
    resetSpaceMenuOptions(contactObj) {
      var options = [];
      // below code is to consolidate all the spaces into one array.
      var allLeases = [];
      if (contactObj != null && contactObj != undefined) {
        if (contactObj.Leases != null && contactObj.Leases != undefined && contactObj.Leases.length > 0) {
             
              let leases = contactObj.Leases
              this.leasesDoc = leases
              allLeases = [...allLeases, ...leases];
        }
        
        let reservations = contactObj.Reservations
        if (reservations!= null && reservations != undefined &&  reservations.length > 0) {

          reservations.forEach(obj=>{
            allLeases.push(obj.Lease)
          })
        }
        
          let pending = contactObj.Pending
        if (pending != null && pending != undefined && pending.length > 0) {
          pending.forEach(obj=>{
            allLeases.push(obj)
          })
        }
        

        
      }
      console.log('all leaseds',allLeases);
      if (allLeases != null && allLeases != undefined) {
        allLeases.forEach(lease => {
          if (this.currentPropertyId) {
            if (lease.Unit.property_id == this.currentPropertyId) {
              var updatedValue = {
                id: lease.id,
                Unit: {
                  number: lease.Unit.number,
                  type: lease.Unit.type,
                  property_id:lease?.Unit?.property_id,
                  state:lease?.Unit?.state
                },
                end_date:lease.end_date
              }
              options.push(updatedValue);
            }
          } else {
            var updatedValue = {
              id: lease.id,
              Unit: {
                number: lease.Unit.number,
                type: lease.Unit.type,
                unit_type_id: lease.Unit.unit_type_id,
                property_id:lease?.Unit?.property_id,
                state:lease?.Unit?.state
              },
              end_date:lease.end_date

            }
            options.push(updatedValue);
          }
        })
      }
    
      // options.sort((a,b)=>{
      //   let state = (item)=>(item.Unit.state ==='Leased'? 0:1)
      //   return state(a)-state(b)
      // })
      
      options.unshift({ id: null, Unit: { number: 'Tenant', type: 'tenant', unit_type_id: '' } })
      if(this.from == 'national_account' && this.currentPropertyId =='all-property')  options.push({ id: 'national_account', Unit: { number: 'Account', type: 'national_account', } })
      if(this.NationalAccount && this.hasPermission('manage_national_accounts')){
        if (this.InteractionObj.isNational) options.unshift({ id: 'national_account', Unit: { number: 'Account', type: 'national_account', } })
       else {
          const selected = options.find(o => o.id === 'national_account');
         if(selected) options.shift()
        }
      }
        this.mainView = options[0]
        this.mainViewOptions = [...options];
        this.setSelectionFlag = this.mainViewOptions.length > 1;
        this.leasesDocAll = [...options]
    }
,
    onSelectedSpace(spacenumber) {
      console.log('spacenumber',spacenumber);
      this.mainView = spacenumber

      this.getInteractionDetails(true, spacenumber);
    },
    async setOutBoundCallProperty(){
    let propertyIds = [];
    if(this.currentPropertyId){
      propertyIds.push(this.currentPropertyId);
    }
    else if(!this.currentPropertyId && this.layout == "message-center"){
      try{
      const response = await api.get(this, api.CHARM_CONTACT+this.InteractionObj?.id+'?scope=message-center');
      this.contactNotificationObject = response;
      propertyIds.push(this.contactNotificationObject.property_id);
      }catch(exception){
        console.log(exception);
      }
    }else{
     const contactLeases = this.InteractionObj && this.InteractionObj.Leases || [];
     if(this.InteractionObj.Lead?.property_id)
      propertyIds.push(this.InteractionObj.Lead.property_id);
     if(contactLeases.length){
      const LeasePropertyIds = contactLeases.map(lease => lease.Unit?.property_id || "");
      propertyIds = [...propertyIds,...LeasePropertyIds];
     }

     if(this.layout == "leads-list"){
      const reservations = this.activeReservations || [];
      const reservationPropertyIds = reservations.map(reservation => reservation.Lease?.Unit?.Property?.id ||"");
      propertyIds = [...propertyIds,...reservationPropertyIds];
     }else{
      const reservations = this.InteractionObj && this.InteractionObj.Reservations || [];
      const reservationPropertyIds = reservations.map(reservation => reservation.Lease?.Unit?.property_id ||"");
      propertyIds = [...propertyIds,...reservationPropertyIds];
     }
    }
    this.outBoundCallPropertyId = propertyIds.find(property => this.isCharmSubscribedProperty(property)) || null;
    },
    async getContactAccess () {
      try{
        if(this.contact?.id){
          let contactAccess =  await api.get(this, api.CONTACTS + this.contact?.id + '/access')
          this.contact.access = contactAccess.access;
          if(this.callWrapAction){
            this.callEvent(this.callWrapAction);
            this.setChatDimensions();
            this.setCallWrapAction('');
          }
        }
      }catch(exception){
        console.log(exception);
        this.contact.access=[];
      }
    },
    closeInteraction () {
      this.isInteractionActive = false
      this.replyToMessage = false
      this.isFilterVisible = true;
      this.isAddNoteInForeground = false // add note component detection flag
      
      this.updateNoteCategories('addAllNote')
      if(this.dropdownValue === 'note'){ 
        this.showNotesFilter = true // this active tab detection flag
      }else {
        this.showNotesFilter = false
      }
      this.$emit('isShowBackBladeHeader', true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    fetchOnScroll (elem) {
      if(!this.isInteractionActive){
        let { target: { scrollTop, clientHeight, scrollHeight }} = elem;
        let scrolledValue =  Math.round(scrollTop + clientHeight);
        if (scrolledValue >= scrollHeight) {
          const length = this.interactionsCount;
          if ((length%20) === 0 && !this.isLoading(this.$options.name) && !this.isAllRecordsLoaded) {
              this.getInteractionDetails();
          }
        }
      }     
    },
    getNewMessages(payload) {
      this.iframeLoadHistory = {};
      if(payload.contact_id === this.InteractionId){
        this.getInteractionDetails(true);
      }
    }, 
    
    async getInteractionDetails (refresh) {
      if(!refresh && this.filteredChatList.length  && !this.interactionsCount){
      return;
    }
      this.firstTimeLoad = true
      if(!this.isAddNoteInForeground){
      this.showNotesFilter = false
    }
      let offset = this.interactionsCount;
      let limit = 20;
      if(refresh) {
        // this.ChatList = [];
        // this.filteredChatList = [];
        limit = this.interactionsCount;
        offset = 0;
      }
      let path = `/contact-interactions?limit=${20}&offset=${offset}`;
      if (this.filterData) {
        if (this.filterData.selectedDay == 'Custom Range') {
          if (this.filterData.start_date !== '') {
            path += `&start_date=${this.filterData.start_date}`;
          }
          if (this.filterData.end_date !== '') {
            path += `&end_date=${this.filterData.end_date}`;
          }
        }
        else if (this.filterData.selectedDay) {
          path += `&selectedDay=${this.filterData.selectedDay}`;
        }
        if(! (this.filterData?.read && this.filterData?.unread)  ){
          if (this.filterData.read) {
            path += `&read=1`;
          }
  
          if (this.filterData.unread) {
            path += `&read=0`;
          }

        }
      }
      switch (this.dropdownValue) {
        case 'email':
          path = path + '&method=email';
          this.notesCategoryId = ''
          break;
        case 'sms':
          this.notesCategoryId = ''
          path = path + '&method=sms';
          break;
        case 'phone':
            path = path + '&method=phone_call';
            this.notesCategoryId = ''
          break;
        case 'mail':
          path = path + '&method=mail';
          this.notesCategoryId = ''
          break;
        case 'note':
          path = path + '&method=note'
          this.showNotesFilter = true
          if(this.notesCategoryId){
            path += `&notes_category_filter_id=${this.notesCategoryId[0]?.id}`
            this.filteredChatList = [];
          }
          break;
      }
      //if (leaseDetails != null && leaseDetails != undefined && leaseDetails.Unit.number != 'Tenant') {
      if (this.mainView.Unit.number != 'Tenant' && this.mainView.Unit.number != 'Account') {
        path = path + '&space_number=' + this.mainView.Unit.number;
      }
      else if (this.isUnifiedCommunication) {
        if (this.mainView.Unit.number === 'Account') {
          path = path + '&national_account=' + 'all-property&space_number=' + this.mainView.Unit.number;
        }
        else if (this.mainView.Unit.number === 'Tenant') {
          path = path + '&isTenantSearch=true'
        }
      }

      // check if the component is called from national account and Account filter is enabled
        if(this.from == 'national_account'){ 
        // check it is called from all-property view 
        if(this.currentPropertyId === 'all-property' && this.mainView.Unit.number === 'Account') {
          path = path  + '&national_account=' + 'all-property&space_number=' + this.mainView.Unit.number;
        }
        else{
          path = path  + '&national_account=' + this.currentPropertyId;
        } 
      }
      else if(this.currentPropertyId){
        path += `&property_id=${this.currentPropertyId}`
      }

      
      if(this.InteractionId){
        let r = await api.get(this, api.CONTACTS + this.InteractionId + path);
        console.log("R", r)
        for (let i = 0; i < r.Interactions.length; i++) {
          r.Interactions[i].showMoreAttachments = false;
        }
        if(refresh) {
            this.filteredChatList = [];
          }
      
        
          this.isAllRecordsLoaded = false;
          if (r.Interactions.length) {
            this.filteredChatList = [...this.filteredChatList, ...r.Interactions];
            // this.ChatList = this.filteredChatList;
            this.filteredChatList = this.sortAndFormatChatList(this.filteredChatList);
        }
        this.firstTimeLoad = false
      }
      if(this.scrollForFirstTime){
        this.$nextTick(()=>{
        this.scrollElementToView()
      })
      }
      this.scrollForFirstTime = false
      console.log("FILTERED CHAT LIST", this.filteredChatList)
        
    },

    getFileSrc(file){
                let src = file.Revisions && file.Revisions.length ? file.Revisions[file.Revisions.length - 1 ].src: file.src;
                if(!src) return;
                let domain = /:\/\/([^\/]+)/.exec(src)[1];
                domain = domain.split('.')[1] ? domain.split('.')[0] : false;
                if(domain && domain.includes('api') && domain !== process.env.VUE_APP_API_SUBDOMAIN) {
                  src = src.replace(domain,process.env.VUE_APP_API_SUBDOMAIN);
                }
                return src;
            },
    async getFile(file){
       this.$http.get(api.UPLOADS + `files/${file.id}/${file.filename}`, { responseType: "arraybuffer" }).then(res => {
        var blob = new Blob([res.body], {
          type: res.headers.get("content-type")
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =  file.filename;
        link.click();
      });
    

    },

    async pinNote(note){
      console.log('contact',this.contact?.id,note.id);
      note.pinned = 1;
      if (note.DeliveryMethod){
        await api.post(this, api.CONTACTS + this.contact?.id + '/pinned-interactions',note);
      } else {
         await api.put(this, api.NOTES + '/' + note.id, note);
      }
      
      EventBus.$emit('pinned_interaction', note);
      let filteredChatListSorted = this.filteredChatList;
      this.filteredChatList = []
      this.filteredChatList = this.sortAndFormatChatList(filteredChatListSorted);
      
      this.$emit('interactorUpdate', {contact_id: this.contact?.id});
      
    },
    async unPinNote(note){
      console.log('contact',this.contact?.id,note.id);
       note.pinned = 0;
      if (note.DeliveryMethod) {
          await api.post(this, api.CONTACTS + this.contact?.id + '/pinned-interactions/' + note.id);
      } else {
          await api.put(this, api.NOTES + '/' + note.id, note);
      }
      let filteredChatListSorted = this.filteredChatList;
      this.filteredChatList = []
      this.filteredChatList = this.sortAndFormatChatList(filteredChatListSorted);
      EventBus.$emit('pinned_interaction', note);
     },
    onMessageShow (note, emailSubject) {
      this.replyToMessage = true;
      if (!note.DeliveryMethod) this.onNoteShow();
      switch (note.DeliveryMethod && note.DeliveryMethod.delivery_type) {
        case "email":
          this.emailSubject = emailSubject
          if (note.content) {
            const parsedDate = moment(note.created, "YYYY-MM-DD HH:mm:ss");
            const formattedDate = parsedDate.format("ddd, MMM DD, YYYY [at] HH:mm");
            const relpyEmailDoubleSpaceContent = (this.replyToMessage) ? '<br></br><br></br>' : '';
            const relpyEmailSingleSpaceContent = (this.replyToMessage) ? '<br></br>' : '';
            const chatEndbar = '\n<div> On ' + formattedDate + '&lt;<a target="_blank" rel="noopener noreferrer nofollow" href="mailto:' + note.Email.from_email + '">' + note.Email.from_email + '(' + note.Email.from_name + ')' + '</a>&gt; wrote:<br></div>';
            const quotedContent = '<blockquote>' + note.content + '</blockquote>';
            this.mailContent = relpyEmailDoubleSpaceContent + chatEndbar + quotedContent;
            this.mailOldContent = relpyEmailSingleSpaceContent + chatEndbar + quotedContent;
          }
          this.onEmailShow();
          break;
        case "sms":
          this.onSmsShow();
          break;
        case "phone":
          this.onPhoneShow();
          break;
        default:
          break
      }

      
      
    },
    onEmailShow () {
      this.isInteractionActive = true;
      this.showEmail = true;
      this.showPhone = false;
      this.showSms = false;
      this.showNote = false;
      this.showNotesFilter = false;
      this.isFilterVisible = false;
      this.$emit('isShowBackBladeHeader', false);
      setTimeout(() => {
        this.$store.dispatch('charmCallStore/setLoadingAction', {"status": false, "eventStatus": "email-button-clicked"})
      }, 2000);
    },
    onSmsShow () {
      this.isInteractionActive = true
      this.showSms = true
      this.showPhone = false
      this.showEmail = false
      this.showNote = false
      this.showNotesFilter = false;
      this.isFilterVisible = false;
      this.$emit('isShowBackBladeHeader', false);
      setTimeout(() => {
        this.$store.dispatch('charmCallStore/setLoadingAction', {"status": false, "eventStatus": "text-button-clicked"})
      }, 2000);
    },
    onNoteShow (data = {}) {
      if(data.status == 'error' || data.status == 'Error'){
        this.errorCheck = true;
        this.noteInteractionId = data.id;
      }else{
        this.errorCheck = false;
        this.noteInteractionId='';
      };
      if(data.DeliveryMethod?.delivery_type == 'mail' || data.DeliveryMethod?.gds_key == 'registered_email'){
        this.mailNotes = true;
        this.showNotesFilter = false
        this.noteInteractionId = data.id
      }else{
        this.mailNotes = false;
        this.showNotesFilter = true;
        this.noteInteractionId='';
      }
      this.isInteractionActive = true
      this.showNote = true
      this.showPhone = false
      this.showSms = false
      this.showEmail = false
      this.isFilterVisible = false;
      this.isAddNoteInForeground = true;
      this.$emit('isShowBackBladeHeader', false);
      // this.getNoteCatagories()
      setTimeout(() => {
        this.$store.dispatch('charmCallStore/setLoadingAction', {"status": false, "eventStatus": "call-notes-button-clicked"})
      }, 2000);
    },
    onPhoneShow () {
      this.showNotesFilter = false;
      this.isInteractionActive = true
      this.showPhone = true
      this.isFilterVisible = false;
      this.showEmail = false
      this.showSms = false
      this.showNote = false
      this.$emit('isShowBackBladeHeader', false);
    },
    onChatError (error) {

      this.$store.dispatch('charmCallStore/stopLoadingAnimation')
      this.chatError = error
      if(this.dropdownValue !== 'note'){
        this.isAddNoteInForeground = this.showNotesFilter = false
      }else{
        this.isAddNoteInForeground = false
         this.showNotesFilter = true
      }
    },
    clearChatError() {
      this.chatError = ''
    },
    onEmailClose (emailObj) {
      this.replyToMessage = false;
      this.showEmail = false;
      this.isInteractionActive = false;
      this.isFilterVisible = true;
      this.getInteractionDetails(true);
      this.$emit('isShowBackBladeHeader', true);
      this.$emit('interactorUpdate', {contact_id: this.contact.id})
    },
    onSmsClose (reloadData) {
      this.replyToMessage = false;
      this.isInteractionActive = false
      this.showSms = false
      this.isFilterVisible = true;
      this.$emit('isShowBackBladeHeader', true);
      this.getInteractionDetails(true)  
      this.$emit('interactorUpdate', {contact_id: this.contact.id})
    },
    onNoteClose (reloadData) {
      this.isAddNoteInForeground = false
      this.replyToMessage = false;
      // console.log('on notes close',this.notesCategoryId[0]?.notes_category);
      if (reloadData) {
        // this.getInteractionDetails(true)
        /* if(this.notesCategoryId){
          this.getFilterNotes(this.notesCategoryId[0].notes_category)
        }
        else { */
      this.getFilterNotesTriggerFlag = !this.getFilterNotesTriggerFlag // twice call issue form hb select
      this.getFilterNotes('All Notes')
        // }
      this.$root.$emit('refresh_contacts')
    }
      this.isFilterVisible = true;

      this.isInteractionActive = false
      this.showNote = false
      this.updateNoteCategories('addAllNote')
      if(this.dropdownValue === 'note'){
        this.showNotesFilter = true
      }else{
        this.showNotesFilter = false
      }
      this.$emit('isShowBackBladeHeader', true);
    },
    onPhoneClose (reloadData) {
      this.replyToMessage = false;
      this.isInteractionActive = false
      this.showSms = false
      this.isFilterVisible = true;
      this.$emit('isShowBackBladeHeader', true);
      this.getInteractionDetails(true)  
      this.$emit('interactorUpdate', {contact_id: this.contact.id})
    },
    viewContact (contactId) {
      this.$emit('viewContact',contactId)
    },
    getSMSDetails(){
      api.get(this, api.MAINTENANCE  + this.InteractionId).then(r => {
        this.contact = r.maintenance.Contact
        // r.maintenance.Thread = r.maintenance.Thread.reverse()

        const groups = r.maintenance.Thread.reduce((groups, chat) => {
        const date = chat.date.split(' ')[0];
        if (!groups[date]) {
          groups[date] = [];
        }
          groups[date].push(chat);
          return groups;
        }, {});

        let newChatList = []
        const groupArrays = Object.keys(groups).map((date) => {
          newChatList.push({
            messageType: 'date',
            date
          })

          groups[date].forEach(chat => {
            newChatList.push({
              messageType: 'chat',
              ...chat
            })
          })
        });
        r.maintenance.Thread = newChatList
        this.filteredChatList = newChatList
        this.ChatObj = r.maintenance

      });
    },
    getWindowSize(){
      var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
      return {x,y}
    },
    setChatDimensions () {
      if (!this.chatWindowHeight) return

      var windowSize = this.getWindowSize()
      let height = 0
      const chatHeight = this.chatWindowHeight;
      console.log("chatHeight", chatHeight, windowSize.y);
      if (windowSize.y <= 768) {
        if (chatHeight === 'small') {
          height = windowSize.y - 400;
        } else if (chatHeight === 'medium') {
          height = windowSize.y - 334;
        } else if (chatHeight === 'large') {
          height = windowSize.y - 310;
        } else if (chatHeight === 'x-large') {
          height = windowSize.y - 295;
        }
      } else {
        if (chatHeight === 'small') {
          height = windowSize.y - 532;
        } else if (chatHeight === 'medium' && !this.hideHeader) {
          height = windowSize.y - 336;
        } else if (chatHeight === 'medium' && this.hideHeader) {
          height = windowSize.y - 480;
        } else if (chatHeight === 'large') {
          height = windowSize.y - 310;
        } else if (chatHeight === 'x-large') {
          height = windowSize.y - 290;
        }
      }
      this.textAreaHeight = height - 195;
      this.threadStyle.height = height + 'px';

    },
    sortAndFormatChatList (interactionList) {
      this.activeIndex = 0;
      let notelist = interactionList.filter(x=> x.pinned === 1).sort((a,b)=> new Date(b.created) - new Date(a.created));
      let unreadList = interactionList.filter(x => x.read === 0).sort((a,b)=> new Date(b.created) - new Date(a.created));
      let otherChat = interactionList.filter(x=> x.pinned !== 1)
      otherChat = otherChat.filter(x=> x.read !== 0).sort((a,b)=> new Date(b.created) - new Date(a.created));
      let finalChat = [];
      
      finalChat = finalChat.concat(notelist);
      finalChat = finalChat.concat(unreadList);
      finalChat = finalChat.concat(otherChat);
    
    finalChat = finalChat.map(item => {
      return { ...item, expanded: false }
    })
      return finalChat;
    },
    async markStatus (messageObj, i) {
      this.activeIndex = i;
      if ((messageObj.read || 0) === 0 && this.hbCommunicationData(messageObj)?.type != 'note') {
        messageObj.read = 1;
        let body = {
          read: messageObj.read,
          resolved: true
        }
        let response = await api.put(this, api.INTERACTIONS + messageObj?.id, body);
        this.$emit('interactorUpdate', {contact_id: messageObj?.Contact?.id});
        EventBus.$emit('update-charm-call-status')
      }
    },

    onMakePhoneCall(){
       let tranformedObject ={};
      if(this.layout == "message-center" && this.contactNotificationObject){
        tranformedObject = this.contactNotificationObject;
        tranformedObject.phone_number = this.contactNotificationObject?.contact_phone;
      }else{
        let currentProperty = {};
        if(this.properties.length){
        currentProperty = this.properties.find(property => property.id == this.outBoundCallPropertyId) || {};
        }
        const notificationObject = new CharmNotification(this.InteractionObj);
        if(this.layout == "leads-list"){
          notificationObject.setLeadContactUnits(this.outBoundCallPropertyId,this.activeReservations);
          notificationObject.setUpLeadReservation(this.activeReservations);
        }else{
          notificationObject.setContactUnits(this.outBoundCallPropertyId);
          notificationObject.setUpReservation();
        }
        tranformedObject = notificationObject.transformData({
          property_id: this.outBoundCallPropertyId,
          communication_property: currentProperty && currentProperty.name,
          contact_access_codes: this.contact.access && this.contact.access.map(access => access.pin) || null,
          status: "call out"
        });
      }
      this.makeOutboundCall({contact: tranformedObject, agent: this.loggedInUser});
    },

    closeShowSelectActionModal(){
      this.showSelectActionModal = false;
    },
    actionConfirmed(action) {
      if (action === 'outgoing-phone') {
        this.onMakePhoneCall();
      } else {
        this.onSmsShow();
      }
    },

    phoneNumberClicked(){
      if(this.outBoundCallPropertyId && this.hasPermission('mini_charm') && this.hasCharmSubscribedPropertySelected){
        if (this.layout === 'message-center' && !Object.keys(this.contactNotificationObject)?.length) {
          EventBus.$emit("onSmsShow");
        } else {
          this.showSelectActionModal = true;
        }
      }else{
        EventBus.$emit("onSmsShow");
      }      
    },
    callEvent(sub){
      switch (sub) {
       case "onNoteShow":
        this.onNoteShow();
        break;

       case "onEmailShow":
         this.onEmailShow();
        break;

        case "onSmsShow":
         this.onSmsShow();
         break;
      }
    },
    scrollElementToView(msg){
        let el = document.getElementsByClassName('isRead');
        if(el.length > 0) el[0].scrollIntoView({block: "start",behavior:'smooth'});
    },
    hbAlternateData(r) {
      if ( r?.contact_type === null  || r?.contact_type?.toLowerCase() === 'primary' || this.hbCommunicationData(r)?.type === 'note') {
        return {
          status: false,
          contact_id: '',
          contact_type: '',
          contact_name: '',
          contact_email: '',
          contact_phone: ''
        };
      }
      else {
        let relationShipContactData;
        if (r?.contact_type?.toLowerCase() === 'military') {
          relationShipContactData = [this.contactFullData.Military.CommandingOfficer]
        }
        else {
          relationShipContactData = this.contactFullData.Relationships.filter(item => item.Contact.id === r.contact_id).map(item => {
            return {
                first: item?.Contact?.first ?? '',
                last: item?.Contact?.last ?? '',
                email:item?.Contact?.email ?? '',
                phone: item?.Contact?.Phones[0]?.phone?? ''
              }
          })
          }
        
        
        return {
          status: relationShipContactData.length > 0 ,
          contact_id: r.contact_id,
          contact_type: this.capitalizeFirstLetter(r.contact_type),
          contact_name:this.capitalizeFirstLetter(relationShipContactData[0]?.first) + ' ' + this.capitalizeFirstLetter(relationShipContactData[0]?.last),
          contact_email: relationShipContactData[0]?.email,
          contact_phone: relationShipContactData[0]?.phone
        };
      }
    },
    capitalizeFirstLetter(inputString){
      if(typeof inputString === 'string'){
      return inputString.charAt(0).toUpperCase() + inputString.slice(1)
    }else{
      return ''
    }
    },
    checkforMMScontent(content, r) {
      // Check if the Text Message contains all the required MMS URI
      if (r.DeliveryMethod?.delivery_type === 'sms') {
        if (content && content.includes("https://api.twilio.com") && content.includes("/Messages/MM") && content.includes("/Media/ME")) {
          return `<a href="${content}"  target="_blank"> MMS </a>`;
        }
      }
      return content;
    },
    hbCommunicationData(r) {
      if (r.status === 'delivered') {
        r.status = 'Delivered';
      }else if(r.status === 'scheduled'){
        r.status = 'In Progress'
      }else if(r.status === 'error' && r.resolved === 0){
        r.status = 'Error'
      }else if(r.status === 'bounced'){
        r.status = 'Bounced'
      }else if(r.status === 'sent' || r.status === 'success' || r.status === 'Success'){
        r.status = 'Sent'
      }else if(r.status === 'pending'){
        r.status = 'Pending'
      }else if(r.status === 'Queued'){
        r.status = 'In Progress'
      }else if(r.status === 'opened'){
        r.status = 'Opened'
      }else if(r.resolved === 1){
        r.status = 'Resolved';
        r.description = 'This was resolved by the manager / operator';
      }else{
        r.status
      }
      if (!r.DeliveryMethod) {
        return {
          type: 'note',
          adminName: (r.EnteredBy?.first ? r.EnteredBy?.first : '') + (r.EnteredBy?.last ? ' ' + r.EnteredBy?.last : ''),
          space: `${r.unit_number == 'Tenant' || r.unit_number == null  || r.unit_number == '' ? 'Tenant' : 'Space ' + r.unit_number}`,
        };
      }

      switch (r.DeliveryMethod.delivery_type) {
        case 'sms':		  
          if (r.EnteredBy) {
            if ((r.Contact && r.Contact?.id === r.EnteredBy?.id) || r.EnteredBy === null) {
              return {
                type: 'text in',
                space: `${r.space === 'Tenant' || r.space === 'Account' ? r.space : 'Space ' + r.space}`,
              };
            } else {
              return {
                type: 'text out',
                space: `${r.space === 'Tenant' || r.space === 'Account' ? r.space : 'Space ' + r.space}`,
                adminName: (r.EnteredBy?.first ? r.EnteredBy?.first : '') + (r.EnteredBy?.last ? ' ' + r.EnteredBy?.last : '')
              };
            }
          } else {
            return {
              type: 'text out',
              space: `${r.space === 'Tenant' || r.space === 'Account' ? r.space : 'Space ' + r.space}`,
              systemGenerated: true
            };
          }
        case 'email':
          if (r.DeliveryMethod.gds_key != "registered_email") {
            if (r.EnteredBy) {
              if (r.Contact && r.EnteredBy?.id === r.Contact?.id) {
                return {
                  type: 'email in',
                  space: `${r.space === 'Tenant' || r.space === 'Account' ? r.space : 'Space ' + r.space}`,
                };
              } else {
                return {
                  type: 'email out',
                  space: `${r.space === 'Tenant' || r.space === 'Account' ? r.space : 'Space ' + r.space}`,
                  status : r.status,
                  statusTime: r.email_timelines?.status ? this.lastStatusTime(r.email_timelines?.status)?.time :(r.email_timelines?.scheduled ?? r.email_timelines?.sent),
                  time: r.email_timelines?.status?.delivered || '',
                  adminName: (r.EnteredBy?.first ? r.EnteredBy?.first : '') + (r.EnteredBy?.last ? ' ' + r.EnteredBy?.last : '')
                };
              }
            } else {
              return {
                type: 'email out',
                space: `${r.space === 'Tenant' || r.space === 'Account' ? r.space : 'Space ' + r.space}`,
                status : r.status,
                statusTime: r.email_timelines?.status ? this.lastStatusTime(r.email_timelines?.status)?.time : (r.email_timelines?.scheduled ?? r.email_timelines?.sent),
                time: r.email_timelines?.status?.delivered || '',
                systemGenerated: false, //make true, once communication time logic confirms
                adminName: 'System'
              }
            }
          }else {
            return {
                type: 'document',
                delivery_method: r.DeliveryMethod.name,
                document_name : r?.Upload[0]?.name,
                delivered_by : r.DeliveryMethod.mail_delivered_by,
                status : r.DeliveryMethod?.gds_key != 'no_delivery_method' ? r.status : '',
                space: `${r.space === 'Tenant' || r.space === 'Account' ? r.space : 'Space ' + r.space}`,
                description : r.description,
              };
          }
        case 'phone_call':
          if (r.Phone) {
            if (r.Phone.direction === 'outgoing') {
              return {
                type: 'call out',
                playbackUrl: r.Phone.recording_url,
                status: r.Phone.status
              };
            } else {
              if (r.Phone.status === 'no-answer' && r.Phone.recording_url) {
                return {
                  type: 'voicemail',
                  voicemailUrl: r.Phone.recording_url,
                  source: r.Phone.source_tag
                };
              } else if (!r.Phone.recording_url && (r.Phone.status === 'no-answer' || r.Phone.status === 'cancelled')) {
                return {
                  type: 'missed',
                  source: r.Phone.source_tag
                };
              } else {
                return {
                  type: 'call in',
                  playbackUrl: r.Phone.recording_url,
                  source: r.Phone.source_tag,
                };
              }
            }
          }else{
            if (r.EnteredBy) {
              return {
                type: 'call out',
                playbackUrl: '',
                source: '',
                adminName: (r.EnteredBy?.first ? r.EnteredBy?.first : '') + (r.EnteredBy?.last ? ' ' + r.EnteredBy?.last : ''),
              };
            }
            else if(r.CreatedBy){
              return {
                type: 'call in',
                playbackUrl: '',
                source: '',
                adminName: (r.CreatedBy?.first ? r.CreatedBy?.first : '') + (r.CreatedBy?.last ? ' ' + r.CreatedBy?.last : ''),
              };
            }
             else {
              return {
                type: 'call in',
                playbackUrl: '',
                source: '',
              };
            }
          }       
        case 'mail':
          return {
                type: 'document',
                delivery_method: r.DeliveryMethod.name,
                document_name : r?.Upload[0]?.name,
                delivered_by : r.DeliveryMethod.mail_delivered_by,
                status : r.DeliveryMethod?.gds_key != 'no_delivery_method' ? r.status : '',
                description : r.description,
                space: `${r.space === 'Tenant' || r.space === 'Account' ? r.space : 'Space ' + r.space}`,
              };

      }
    },
    lastStatusTime(times){
      const lastKey = Object?.keys(times).at(-1);
      return {
        status: lastKey,
        time: lastKey ? times[lastKey] : ''
      };
    },
    async downloadfile(upload) {
      let id = upload.id
      try {
        const downloadedFile = await api.get(this, api.UPLOADS + `${id}/download`);
        const fileContent = downloadedFile.file;
        var buffer = Buffer.from(fileContent.Body.data);
        var blob = new Blob([buffer], { type: downloadedFile.mimetype });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = downloadedFile.name;
        link.click();
      } catch (e) {
        const fileUrl = upload.src;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank';
        link.download = upload.name ? upload.name : upload.DocumentType.name ? upload.DocumentType.name : "attachment"
        link.click();
      }
    },
    
    hbDateFormat(dateTime) {
      const daysDiff = moment.utc(dateTime).local().calendar();
      const targetDate = moment.utc(dateTime).local();
      if (daysDiff.split(' ')[0] === 'Today') {
        return `Today, ${targetDate.format('h:mma')}`;
      } else if (daysDiff.split(' ')[0] === 'Yesterday') {
        return `Yesterday, ${targetDate.format('h:mma')}`;
      } else {
        return targetDate.format('MMM D, YYYY @ h:mma');
      }
    },
    changePinStatus(r,index){

      if(r.pinned){
        this.unPinNote(r);
      }else{
        this.pinNote(r);
      }
    },
    async getFilterNotes(categoryName){
      // set notes category id when All notes are not selected 

    if(this.getFilterNotesTriggerFlag)
    {
      if(typeof categoryName == 'string' && categoryName !== 'All Notes'){
      this.notesCategoryId = this.getNoteCategoryId(categoryName)
      }else{
        this.notesCategoryId='' // set empty for all notes
      }
    // only load notes when notes tab is in foreground
      if(!this.isAddNoteInForeground){
        this.getInteractionDetails(true)
      }
    }
    // if(whereIsIt !== 'FROM_NOTE_CLOSE')
      this.getFilterNotesTriggerFlag = !this.getFilterNotesTriggerFlag // to prevent the bug from same event being trigged twice
    },
    async getNoteCatagories(property_id){
      this.noteCategoryResponse = await api.get(this, api.NOTES_CATEGORIES + '?property_id='+property_id)
      this.noteCatagories = this.noteCategoryResponse.notes_catagories.map(item => item.notes_category).sort((a, b) => a.localeCompare(b))
      // this.setMiscellaneousATop()
      this.noteCatagories.unshift("All Notes")
      //console.log('get notes called',this.noteCatagories,api.NOTES_CATEGORIES);

    },
    // setMiscellaneousATop(){
    //   this.noteCatagories.splice(this.noteCatagories.indexOf('Miscellaneous'),1)
    //     this.noteCatagories.unshift('Miscellaneous')
    // },
    updateNoteCategories(value){
      if(value === 'removeAllNote'){
        this.noteCatagories.shift()
        // this.setMiscellaneousATop()
        this.notesCategoryId = this.getNoteCategoryId(this.noteCatagories[0] != 'All Notes' ? this.noteCatagories[0] : this.noteCatagories[1] )  
      }
      else{
        if(!this.noteCatagories.includes("All Notes")){
        this.noteCatagories.unshift("All Notes")
      }

      }
    },
    getNoteCategoryId(category){
      return this.noteCategoryResponse.notes_catagories.filter(item => item.notes_category.toLowerCase() == category?.toLowerCase())
    },
    setEmailList(contactObj) {
      this.contactEmailList = [];
      // If from is 'national_account'
      if (this.from === 'national_account') {
        if (this.currentPropertyId === 'all-property') {
          // Include primary email
          if (this.contact?.email) {
            this.contactEmailList.unshift({ id: this.InteractionObj.id, email: this.contact.email + ' (Primary)' });
          }
          // Include only primary and is_national_account_alt
          contactObj.Relationships.forEach(item => {
            const emailType = this.getEmailType(item);
            if (emailType === item.Contact.title) {
              this.contactEmailList.push({ id: item.Contact.id, email: item.Contact.email + ' (' + emailType + ')' });
            }
          });
        } else {
          // If not 'all-property', exclude primary and include other cases from getEmailType
          this.contactEmailList.push(
            ...contactObj.Relationships.map(item => {
              const emailType = this.getEmailType(item);
              if (emailType !== item.Contact.title) {
                return { id: item.Contact.id, email: item.Contact?.email + ' (' + emailType + ')' };
              }
            }).filter(Boolean) // Filter out undefined values
          );
        }
      } else {
        // If from is NOT 'national_account', restore original behavior
        // Include primary email
        if (this.contact?.email) {
          this.contactEmailList.unshift({ id: this.InteractionObj.id, email: this.contact.email + ' (Primary)' });
        }
        // Map through all relationships and include based on getEmailType logic
        this.contactEmailList.push(
          ...contactObj.Relationships.map(item => {
            const emailType = this.getEmailType(item);
            return { id: item.Contact.id, email: item.Contact?.email + ' (' + emailType + ')' };
          })
        );
      }
      // Add Military and Representative emails, if applicable
      if (contactObj.Military.CommandingOfficer?.email) {
        this.contactEmailList.push({
          id: contactObj.Military.contact_id,
          email: contactObj.Military.CommandingOfficer.email + ' (Military)',
        });
      }
      if (Object.keys(contactObj.Representative).length && contactObj.Representative.Contact?.email) {
        this.contactEmailList.push({
          id: contactObj.Representative.Contact?.id,
          email: contactObj.Representative.Contact?.email + ' (Representative)',
        });
      }
    },
    setPhoneList(contactObj) {
      this.contactPhoneList = [];

      // If from is 'national_account'
      if (this.from === 'national_account') {
        if (this.currentPropertyId === 'all-property') {
          // Include primary phone
          if (this.contact?.Phones?.[0]?.sms === true) {
            this.contactPhoneList.unshift({
              contact_id: this.contact.id,
              id: this.contact.Phones[0].id,
              phone: this.formatPhoneNumber(this.contact.Phones[0].phone) + ' (Primary)',
              type: 'Primary'
            });
          }
          // Include only primary and is_national_account_alt phones
          contactObj.Relationships.forEach(item => {
            const phoneType = this.getEmailType(item); // Assuming the same logic applies for phone type
            if (phoneType === item.Contact.title && item.Contact.Phones?.[0]?.sms === true) {
              this.contactPhoneList.push({
                contact_id: item.Contact.id,
                id: item.Contact.Phones[0].id,
                phone: this.formatPhoneNumber(item.Contact.Phones[0].phone) + ' (' + phoneType + ')',
                type: phoneType
              });
            }
          });
        } else {
          // If not 'all-property', exclude primary and include other cases from getEmailType
          this.contactPhoneList.push(
            ...contactObj.Relationships.map(item => {
              const phoneType = this.getEmailType(item);
              if (phoneType !== item.Contact.title && item.Contact.Phones?.[0]?.sms === true) {
                return {
                  contact_id: item.Contact.id,
                  id: item.Contact.Phones[0].id,
                  phone: this.formatPhoneNumber(item.Contact.Phones[0].phone) + ' (' + phoneType + ')',
                  type: phoneType
                };
              }
            }).filter(Boolean) // Filter out undefined values
          );
        }
      } else {
        // If from is NOT 'national_account', restore original behavior
        // Include primary phone
        let relationshipsContacts = contactObj.Relationships.filter(item => item.Contact.Phones[0]?.sms === true ).map(item => { return { Phone : item.Contact.Phones[0], type: this.getEmailType(item) }})
        this.contactPhoneList = [...contactObj.Relationships.filter(item => item.Contact.Phones[0]?.sms === true ).map(item => { return { contact_id :item.Contact.id , id : item.Contact.Phones[0].id, phone: this.formatPhoneNumber(item.Contact.Phones[0].phone) +' (' +this.getEmailType(item)+')', type:this.getEmailType(item) }}), ...contactObj.Phones.filter(item => item?.sms === true).map(item => {return { contact_id: this.contact.id, id: item.id ,phone: this.formatPhoneNumber(item.phone) + `${item.primary === 1 ? ' (Primary)':' (Non Primary)' }`,type: item.primary === 1 ? 'Primary':'Non Primary' }})]
        if(contactObj.Military.CommandingOfficer.Phone?.sms){ 
        this.contactPhoneList.push({ contact_id: contactObj.Military.contact_id, id: contactObj.Military.CommandingOfficer.Phone.id, phone: this.formatPhoneNumber(contactObj.Military.CommandingOfficer.Phone.phone) + ' (Military)' ,type: 'Military'}) 
          }
          this.contactPhoneList.sort((a, b) => (a.type === 'Primary' ? -1 : b.type === 'Primary' ? 1 : 0))
          }
},
    getEmailType(emails){
      const emailType = Object.keys(emails).find(key => emails[key] === 1);
       switch (emailType) {
         case 'is_alternate':
           return 'Alternate'
           break;
        case 'is_cosigner':
          return 'Cosigner'
          break;
        case 'is_emergency':
          return 'Emergency'
          break;
         case 'is_military':
           return 'Military'
           break;
         case 'is_authorized':
           return 'Authorized'
           break;
         case 'is_lien_holder':
           return 'Lien Holder'
           break;
         case 'is_national_account_alt': // national account level add contact : cc vaibhav 
           return emails.Contact.title
           break;
           case 'is_national_account_representative':
           return 'Representative'
           break;
         default:
           return ''
          break;
       }
    },
    resetAllData(){
      this.filteredChatList= []
      this.firstTimeLoad = true
    },
    handleWidthChange(width){
      this.communicationWidthHalved = width / 2 + 'px';
    },
    sortPrimaryToTop(a, b) {
      if (a.phone.split(' ')[1] > b.phone.split(' ')[1]) {
        return -1;
      }
      if (a.phone.split(' ')[1] < b.phone.split(' ')[1]) {
        return 1;
      }
      return 0;
    },
    formatPhoneNumber(value) {
      if (!value) return '';
                    try {
                        var parsedPhoneNumber = parsePhoneNumber('+' + value);

                        if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                            if(parsedPhoneNumber.country === 'US'){
                                return '+1 ' + parsedPhoneNumber.formatNational();
                            } else {
                                return parsedPhoneNumber.formatInternational();
                            }
                        } else {
                            value = value.toString();

                            var numbers = value.replace(/\D/g, ''), char = {0: '(', 3: ') ', 6: '-'};
                            value = '';
                            for (var i = 0; i < numbers.length; i++) {
                                value += (char[i] || '') + numbers[i];
                            }
                        }
                    }   catch(err){
                    }
                return value;
      // return formattedPhoneNumber
    },
    getPropertyIdForInteractions(contactObj){
      if(contactObj.Leases.length > 0){
         this.interaction_property_id = contactObj?.Leases[0]?.Unit?.property_id
      }
      else{
        this.interaction_property_id = contactObj?.Lead?.property_id
      }
    },
    filterDataOnSelectedSpace(){
      console.log('space changed');
    },
    // Function to revoke all Blob URLs
    revokeAllBlobURLs() {
      this.mailContentBlobUrls.forEach(url => URL.revokeObjectURL(url));
      this.mailContentBlobUrls = []; // Clear the array after revocation
    }
  },
}

</script>
<style lang="scss" scoped>
  .scroll-shadow {
    box-shadow: 0 -1px 3px rgba(110, 110, 110, 0.27);
  }
  .actionable-header {
    align-items: center !important;
    gap: 4px;
    justify-content: space-between;
    .space-selection {
      margin: 0px !important;
    }
    .mdi-plus-mobile {
      margin: 0px !important;
    }
  }
  .action-1{
      min-width: 80px;
   }
  @media (max-width: 414px) {
    .actionable-header {
      justify-content: flex-start;
    }
  }
</style>
<style scoped>
.communication-center-style {
  padding-top: 24px !important;
  padding-bottom: 8px !important;
  min-height: 111px;
}
@media (max-width: 801px) {
  .communication-center-style {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    min-height: 94px;
  }
}
.mail-container-iframe {
  -ms-overflow-style: none;  /* Internet Explorer */
    scrollbar-width: none !important;   /* Firefox */
    border: none !important; 
}
iframe.mail-container-iframe body {
    -ms-overflow-style: none !important; /* Internet Explorer */
    scrollbar-width: none !important;    /* Firefox */
}

iframe.mail-container-iframe::-webkit-scrollbar  body { 
    display: none !important;  /* Safari and Chrome */
}

/* .isRead >>> .hb-communication-background {
  background: #e0f8f8!important;
} */

/* Hide scrollbar for Chrome and Safari */
  .mail-container-iframe::-webkit-scrollbar {
    display: none;
  }
  
.chat-list-container, .send-email-template {
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
 }
.isPinned >>> .hb-communication-background{
  background: #FFF9DA!important;
}
  .action-time-font {
    font-size: 10px;
  }
  .timeline-text{
    font-size: 12px;
    color: #637381;
  }
  .chat-list {
    overflow-x:hidden;
  }
  .email-label {
    background-color: #F9FAFB;
    height: 48px;
    color: #101318;
  }
  /* .unread-message {
    background-color: #F7FCFC !important;
    background-color: #edfcfc !important;
    box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.1);
    cursor: pointer;
  } */
  .dense-tab {
    min-width: 60px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .chat-window-interaction-type-holder,
  .chat-window-container{
    display: flex;
    /* height: 100%; */
    flex-direction: column;
    background: white;

  }
  .interaction-action-bar,
  .chat-window-actions,
  .chat-window-nav,
  .chat-window-header.theme--light{
    flex: 0 1;
    background: #FFFFFF!important;
  }

  .interaction-action-bar,
  .interaction-action-bar i{
    background: #E0F5F5!important;
  }

  .interaction-window {
    display: flex;
    flex-direction: column;
  }

  /* .loader-div {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: grid;
  place-items: center;
  } */

  .chat-item {
    /* background: #F9FAFB; */
    box-shadow: inset 0px -1px 0px rgba(11, 18, 29, 0.1);
  }
  .menu-list > .v-list-item:hover{
    background-color: #E0F5F5;
  }

  /* .pinned-msg {
    background-color: #FFF9DA !important;
  } */

  .chat-error {
    padding: 10px;
  }

  .cw-list {
    white-space: pre-wrap;
    line-height: 18px; 
    text-transform: none;
  }
  .interaction-action-bar,
  .compose-title-bar {
    height: 48px;
    background: #E0F5F5;
  }

  .interaction-action-bar,
  .compose-title-bar {
    height: 48px;
    background: #E0F5F5;
  }
  
  .for-space-anchor{
    position: relative;
  }
 /* .theme--light.v-expansion-panel:not(.v-expansion-panel--active):not(.v-expansion-panel--disabled) {
    color: rgb(236 15 231 / 87%) !important;
  }*/

.filter-date-main{
  display: flex;
  justify-content: space-between;
  margin-top:5px;
}

.theme--light{
  background-color: #F9FAFB !important;
}

.chat-window-hb-communication-default-slot-wrapper >>> p {
  margin-bottom: 8px !important;
}

.chat-window-container .interaction-window >>> .editor-container {
  padding-bottom: 10px !important;
}
/* .chat-window-hb-communication-default-slot-wrapper >>> p:first-child {
  margin-top: 45px !important;
} */

.chat-window-hb-communication-default-slot-wrapper >>> p {
  margin-bottom: 8px !important;
}

.chat-window-container .interaction-window >>> .editor-container {
  padding-bottom: 10px !important;
}

.container-scroll-auto{
  overflow-y: auto;
}

</style>
